import { subDays, subMonths } from 'date-fns';

const ranges = [
  { value: '7-days', num: 7, label: 'Last 7 days' },
  { value: '14-days', num: 14, label: 'Last 14 days' },
  { value: '30-days', num: 30, label: 'Last 30 days' },
  { value: '3-months', num: 3, label: 'Last 3 months' },
  { value: '6-months', num: 6, label: 'Last 6 months' },
];

const types = [
  { value: 'all', label: 'All' },
  { value: 'games', label: 'Games' },
  { value: 'competitions', label: 'Competitions' },
  { value: 'practices', label: 'Practices' },
];

function mapRangeToDate(range) {
  const [, fn] = range.split('-');
  const dateFn = fn === 'days' ? subDays : subMonths;
  return dateFn(new Date(), ranges.find((r) => r.value === range).num);
}

function calcPercentage(numerator, denominator) {
  if (denominator === 0) {
    return 0;
  }
  return Math.round((numerator / denominator) * 100);
}

function getStatsPercentages({ stats }) {
  if (!stats) {
    return {
      practice: 0,
      game: 0,
      competition: 0,
      combined: 0,
    };
  }
  return {
    practice: calcPercentage(stats.practice.attended, stats.practice.invited),
    game: calcPercentage(stats.game.attended, stats.game.invited),
    competition: calcPercentage(stats.competition.attended, stats.competition.invited),
    combined: calcPercentage(stats.combined.attended, stats.combined.invited),
  };
}

export { ranges, types, mapRangeToDate, getStatsPercentages };
