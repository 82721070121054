import gql from 'graphql-tag';

const GET_PROSUBSCRIPTIONS_BY_ORDERID = gql`
  query GetProSubscriptions($orderid: String!) {
    getSubscriptionsWithOrderID(input: { orderid: $orderid }) {
      _id
      licenseKey
      teams {
        _id
        name
        color
        code
      }
    }
  }
`;

const GET_PROSUBSCRIPTIONS_BY_SUBSCRIPTIONID = gql`
  query GetProSubscriptionsBySubscription($subscriptionid: String!) {
    getSubscriptionsWithSubscriptionID(input: { subscriptionid: $subscriptionid }) {
      _id
      licenseKey
      teams {
        _id
        name
        color
        code
      }
    }
  }
`;

const GET_TEAM_BY_CODE = gql`
  query GetTeamByCode($code: String!) {
    getTeamByCode(input: { code: $code }) {
      _id
      name
      color
    }
  }
`;

export {
  GET_PROSUBSCRIPTIONS_BY_ORDERID,
  GET_PROSUBSCRIPTIONS_BY_SUBSCRIPTIONID,
  GET_TEAM_BY_CODE,
};
