import _ from 'lodash';

const choices = {
  going: true,
  'not-going': false,
  'no-answer': null,
};

const getAttendingAnswer = _.memoize((attending) =>
  _(choices)
    .map((val, key) => val === attending && key)
    .find(_.identity),
);

export { choices };

export default getAttendingAnswer;
