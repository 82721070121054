import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import AuthenticationContext from 'contexts/Authentication';
import { loginByCode, requestPasscode, validatePhoneNumber } from '../../network/auth';

const isValid = {
  email: new RegExp(/^(.+)@(.+)\.(.{2,})$/i),
  phone: new RegExp(/^\+[0-9]{4,}$/i),
};

function useSignIn() {
  const [credential, setCredentialValue] = useState('');
  const [credentialType, setCredentialType] = useState('email');
  const [signingIn, setSigningIn] = useState(false);
  const [verificationRef, setVerificationRef] = useState('');
  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { afterSigninRoute } = useContext(AuthenticationContext);
  const canSubmit = useMemo(
    () =>
      (credential && isValid[credentialType].test(credential)) ||
      (verificationRef.length > 0 && code.length === 4),
    [credential, credentialType, verificationRef, code],
  );
  const setCredential = useCallback(
    (credential) => {
      if (credentialType === 'phone') {
        setCredentialValue(
          credential?.length > 0 && credential.indexOf('+') !== 0 ? `+${credential}` : credential,
        );
      } else {
        setCredentialValue(credential);
      }
    },
    [credentialType, setCredentialValue],
  );
  const formSubmit = async (event) => {
    event.preventDefault();
    if (!canSubmit) return Promise.resolve();
    setSigningIn(true);
    setErrorMsg('');
    try {
      if (code.length < 1) {
        if (credentialType === 'phone') {
          try {
            const { data: validated } = await validatePhoneNumber(credential);
            setCredential(validated.phoneNumber);
          } catch (e) {
            alert(e);
            return;
          }
        }
        const { data } = await requestPasscode({
          type: credentialType,
          value: credential,
          checkExisting: true,
        });
        setVerificationRef(data.ref);
      } else if (code.length > 0) {
        await loginByCode(credentialType)({
          verificationRef,
          code,
        });
        window.localStorage.setItem('userCredential', credential);
        // TODO: This aint pretty
        window.location.href = afterSigninRoute;
      }
    } catch (e) {
      if (e?.response?.status) {
        if (e.response.status === 404) {
          setErrorMsg(
            `The ${credentialType} was not found in Heja.\nDownload the app and register first.`,
          );
        }
      }
      Bugsnag.notify(e);
      console.log(e);
    } finally {
      setSigningIn(false);
    }
  };
  useEffect(() => {
    const userCredential = window.localStorage.getItem('userCredential');
    if (userCredential && isValid[credentialType].test(userCredential)) {
      setCredential(userCredential);
    } else {
      setCredential('');
    }
  }, [credentialType, setCredential]);
  return {
    state: {
      credential,
      credentialType,
      signingIn,
      errorMsg,
      canSubmit,
      verificationRef,
      code,
    },
    setCredential,
    setCredentialType,
    setCode,
    formSubmit,
  };
}

export default useSignIn;
