import React, { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import LayoutHeader from 'components/LayoutHeader';
import AttendanceTeam from './AttendanceTeam';
import AttendanceMember from './AttendanceMember';
import AttendanceFilters from './AttendanceFilters';
import { GET_ATTENDANCE } from './gql';
import { mapRangeToDate } from './utils';
import useTeam from 'hooks/useTeam';

function AttendanceScreen() {
  const params = useParams();
  const history = useHistory();
  const team = useTeam();

  const ignoredType = team.activityTags.some((tag) => tag.name === 'Competition')
    ? 'games'
    : 'competitions';

  const selectedType = useMemo(() => params.type || 'all', [params.type]);
  const setSelectedType = (type) => history.push(`/attendance/${type === 'all' ? '' : type}`);
  const [selectedRange, setSelectedRange] = useState('30-days');

  const startdate = useMemo(() => mapRangeToDate(selectedRange), [selectedRange]);
  const { data, loading } = useQuery(GET_ATTENDANCE, {
    variables: { teamid: team._id, startdate },
  });
  const members = useMemo(
    () => _.sortBy(data?.attendance.members ?? [], ({ stats }) => -1 * stats.combined.attended),
    [data],
  );

  return (
    <>
      <LayoutHeader helmetTitle="Attendance" title="Attendance" />
      <AttendanceFilters
        {...{ selectedType, setSelectedType, selectedRange, setSelectedRange, ignoredType }}
      />
      <AttendanceTeam
        loading={loading}
        data={data?.attendance?.overall}
        selectedType={selectedType}
      />
      <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
      {members.map((m) => (
        <AttendanceMember
          key={m.user._id}
          loading={loading}
          member={m}
          selectedType={selectedType}
        />
      ))}
    </>
  );
}

export default AttendanceScreen;
