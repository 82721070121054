import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import AppButton from 'components/AppButton';
import styles from './Modal.module.scss';

const modalRoot = document.getElementById('modal-root');

const Modal = ({ children, onClose }) => {
  const ref = useRef();

  useOnClickOutside(ref, onClose);

  useEffect(() => {
    function listenForEsc(e) {
      e.key === 'Escape' && onClose && onClose();
    }
    document.addEventListener('keyup', listenForEsc, false);
    return () => {
      document.removeEventListener('keyup', listenForEsc, false);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div className={styles.overlay}>
      <div className={styles.content} {...{ ref }}>
        <div style={{ alignSelf: 'flex-end', marginBottom: '0.5rem' }}>
          <AppButton type="close" onClick={onClose} />
        </div>
        {children}
      </div>
    </div>,
    modalRoot,
  );
};

export default Modal;
