import React, { useCallback, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import categories from '../attendanceCategories';
import { SET_RSVP_DETAILS } from '../gql';
import Reason from './Reason';
import styles from './RSVPRow.module.scss';

function RSVPDetails({ activityid, member, onClose }) {
  const [setRSVPDetails, { loading: setDetailsLoading }] = useMutation(SET_RSVP_DETAILS);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [note, setNote] = useState('');
  const handleTextChange = useCallback((e) => setNote(e.target.value), [setNote]);
  useEffect(() => {
    setSelectedCategory(member.attendanceDetails?.category ?? '');
    setNote(member.attendanceDetails?.note ?? '');
  }, [member.attendanceDetails]);
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await setRSVPDetails({
        variables: {
          activityid,
          memberid: member.user._id,
          category: selectedCategory,
          note,
        },
      });
      onClose && onClose();
    },
    [activityid, member.user._id, note, onClose, selectedCategory, setRSVPDetails],
  );

  return (
    <Modal
      onClose={() => {
        onClose && onClose();
        setSelectedCategory(member.attendanceDetails?.category ?? '');
        setNote(member.attendanceDetails?.note ?? '');
      }}
    >
      <Box>
        <h3>Leave a reason</h3>
        <div className={styles.reasons}>
          {categories[member.attending === true ? 'going' : 'not-going']?.map(
            ({ category, icon }) => (
              <Reason
                key={category}
                selected={category === selectedCategory}
                category={category}
                icon={icon}
                onClick={() => setSelectedCategory(category)}
              />
            ),
          )}
        </div>
        <form onSubmit={onSubmit} className={styles.noteForm}>
          <input
            type="text"
            placeholder="Add a custom note…"
            value={note}
            onChange={handleTextChange}
          />
          <Button type="submit" loading={setDetailsLoading}>
            Done
          </Button>
        </form>
      </Box>
    </Modal>
  );
}

export default RSVPDetails;
