import React from 'react';
import Box from 'components/Box';
import ProfileImage from 'components/ProfileImage';
import styles from './AttendanceMember.module.scss';
import AttendanceBar from './AttendanceBar';
import { getStatsPercentages } from './utils.js';
import useTeam from 'hooks/useTeam';

function AttendanceMember({ member, loading, selectedType }) {
  const { stats } = member;
  const { practice, game, competition, combined } = getStatsPercentages({ stats });
  const { activityTags } = useTeam();
  const mainActivity = activityTags.some((tag) => tag.name === 'Game') ? 'Game' : 'Competition';

  const isSingleType = selectedType !== 'all';
  if (isSingleType) {
    const percentage = { games: game, practices: practice, competitions: competition };
    return (
      <Box>
        <div className={styles.singleHead}>
          <div className={styles.member}>
            <ProfileImage user={member.user} size={28} />
            <div className={styles.name}>{member.user?.fullName}</div>
          </div>
          <div className={styles.percentage}>{percentage[selectedType]}%</div>
        </div>
      </Box>
    );
  }
  return (
    <Box noPadding className={styles.memberBox}>
      <header>
        <ProfileImage user={member.user} size={28} />
        <div className={styles.name}>{member.user?.fullName}</div>
      </header>
      <div className={styles.wrapper}>
        {mainActivity === 'Game' ? (
          <div className={styles.col}>
            <strong title={`${stats.game.attended}/${stats.game.invited}`}>{game}%</strong>
            <AttendanceBar foreground="#119FFF" completion={game} loading={loading} />
          </div>
        ) : (
          <div className={styles.col}>
            <strong title={`${stats.competition.attended}/${stats.competition.invited}`}>
              {competition}%
            </strong>
            <AttendanceBar foreground="#119FFF" completion={competition} loading={loading} />
          </div>
        )}
        <div className={styles.col}>
          <strong title={`${stats.practice.attended}/${stats.practice.invited}`}>
            {practice}%
          </strong>
          <AttendanceBar foreground="#FE923E" completion={practice} loading={loading} />
        </div>
        <div className={styles.col}>
          <strong title={`${stats.combined.attended}/${stats.combined.invited}`}>
            {combined}%
          </strong>
          <AttendanceBar foreground="#2C3948" completion={combined} loading={loading} />
        </div>
      </div>
    </Box>
  );
}

export default AttendanceMember;
