import * as React from 'react';

function IconPhone(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M20.933 25.343c-1.096 0-2.636-.396-4.942-1.685-2.805-1.572-4.974-3.024-7.763-5.806-2.69-2.688-3.998-4.428-5.83-7.76C.33 6.327.682 4.355 1.076 3.511c.47-1.007 1.163-1.61 2.059-2.208A9.902 9.902 0 014.743.45l.155-.068c.278-.125.7-.314 1.233-.112.356.134.674.407 1.172.899 1.02 1.006 2.415 3.248 2.93 4.349.345.742.573 1.231.574 1.78 0 .644-.323 1.14-.716 1.675l-.217.29c-.428.562-.522.724-.46 1.014.125.582 1.06 2.316 2.595 3.848 1.535 1.531 3.218 2.406 3.803 2.531.302.065.468-.033 1.048-.476.083-.063.168-.129.258-.194.598-.446 1.071-.761 1.7-.761h.002c.547 0 1.015.237 1.79.628 1.01.51 3.32 1.886 4.333 2.908.492.497.767.814.901 1.17.202.534.012.954-.112 1.235l-.068.154a9.91 9.91 0 01-.859 1.605c-.597.893-1.202 1.585-2.212 2.055a3.786 3.786 0 01-1.66.363z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoIconPhone = React.memo(IconPhone);
export default MemoIconPhone;
