import * as React from 'react';

function AddIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoAddIcon = React.memo(AddIcon);
export default MemoAddIcon;
