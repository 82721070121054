import React from 'react';
import Image from '../../../components/Image';
import styles from './AttachmentPreview.module.scss';

function ImageAttachment({ attachment }) {
  return attachment.uploadData?.secure_url ? (
    <div className={styles.imagePlaceholder}>
      <Image url={attachment.uploadData?.secure_url} width={128} />
    </div>
  ) : (
    <div className={styles.imagePlaceholder}>
      {attachment.fileData && <Image url={attachment.fileData} />}
      <div className={styles.spinner} />
    </div>
  );
}

function DocumentAttachment({ attachment }) {
  return (
    <div className={styles.document}>
      {attachment.loading ? (
        <div className={styles.spinner} />
      ) : (
        <span role="img" aria-label="A checkmark">
          ✅
        </span>
      )}
      {attachment.file?.name}
    </div>
  );
}

function AttachmentPreview({ attachment, remove }) {
  const askToRemove = () => {
    /* eslint-disable-next-line */
    if (confirm('Do you want to remove the attachment?')) {
      remove();
    }
  };
  return (
    <div onClick={askToRemove} role="button" tabIndex={0}>
      {attachment.typename === 'ImageUploadInfo' && <ImageAttachment attachment={attachment} />}
      {attachment.typename === 'DocumentUploadInfo' && (
        <DocumentAttachment attachment={attachment} />
      )}
    </div>
  );
}

export default AttachmentPreview;
