import React from 'react';
import Input from 'components/Input';

export default function Title({ title, setTitle, className }) {
  return (
    <Input
      value={title}
      placeholder="Add title"
      onChange={setTitle}
      label="Title"
      required
      className={className}
    />
  );
}
