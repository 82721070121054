import MainNavigation from 'components/MainNavigation/MainNavigation';
import UserMenu from 'components/UserMenu';
import ChooseTeam from 'features/team/ChooseTeam';
import TeamMemberList from 'features/team/TeamMemberList';
import useTeam from 'hooks/useTeam';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IconClose from '../IconClose';
import IconMenu from '../IconMenu';
import styles from './Layout.module.scss';
import { Logo } from './Logo';

const Layout = ({ children }) => {
  const { _id: teamid } = useTeam();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Close the mobile menu after navigation happens
    setTimeout(() => {
      setMobileMenuIsOpen(false);
    }, 300);
  }, [location]);

  return (
    <div className={styles.bg}>
      <div className={styles.grid}>
        <nav className={styles.nav}>
          <div className={styles.nav__small}>
            <div className={styles.nav__small__top}>
              <Logo navable={!!teamid} className={styles.nav__small__logo} />
              <button
                className={styles.nav__small__toggle}
                onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
              >
                {mobileMenuIsOpen ? <IconClose /> : <IconMenu />}
              </button>
            </div>
            <div style={{ overflow: 'hidden', marginTop: 'var(--size-spacing-small)' }}>
              <AnimatePresence>
                {mobileMenuIsOpen && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{ ease: 'anticipate' }}
                  >
                    <div style={{ height: 'var(--size-spacing-small)' }} />
                    <MainNavigation small />
                    <div style={{ height: 'var(--size-spacing-small)' }} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className={styles.nav__large}>
            <Logo navable={!!teamid} className={styles.nav__large__logo} />
            <MainNavigation />
            {teamid && (
              <div style={{ marginTop: 20 }}>
                <ChooseTeam />
                <p
                  style={{ fontSize: '80%', color: 'var(--color-premium-purple)', paddingTop: 20 }}
                >
                  Payments tracking, Chats and Settings are only available via the Heja app at the
                  moment.
                </p>
              </div>
            )}
          </div>
        </nav>
        <main className={styles.main}>{children}</main>
        <section className={styles.sidebar}>
          <UserMenu />
          {teamid && <TeamMemberList />}
        </section>
      </div>
    </div>
  );
};

export default Layout;
