import GooglePlay from './GooglePlay';
import AppStore from './AppStore';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HejaLogo from '../../components/HejaLogo';
import Signin from './SigninScreen';
import styles from './SigninScreen.module.scss';
import SigninWithCredential from './SigninWithCredential';
import SigninWithFacebook from './SigninWithFacebook';

function SigninStack() {
  return (
    <div className={styles.wrapper}>
      <main className={styles.main}>
        <div className={styles.content}>
          <HejaLogo className={styles.logo} />

          <Switch>
            <Route
              render={(props) => <SigninWithCredential credentialType="phone" {...props} />}
              path="/signin/phone"
            />
            <Route
              render={(props) => <SigninWithCredential credentialType="email" {...props} />}
              path="/signin/email"
            />
            <Route component={SigninWithFacebook} path="/signin/facebook" />
            <Route component={Signin} path="/" />
          </Switch>
        </div>
      </main>
      <aside className={styles.aside}>
        <div className={styles.asideContent}>
          <h2>Sign up</h2>
          <p>Download Heja for mobile to create or join a new team.</p>
          <a href="https://app.adjust.com/o25m4m?fallback=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fheja-simple-communication%2Fid1157335714%3Fmt%3D8%26uo%3D4">
            <AppStore />
          </a>
          <a href="https://app.adjust.com/x2sfom?fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.heja.app">
            <GooglePlay />
          </a>
        </div>
        <img
          className={styles.asidePlayer}
          srcSet={`
            ${require('./player.png')} 1x,
            ${require('./player@2x.png')} 2x
          `}
          alt=""
          role="presentation"
        />
      </aside>
    </div>
  );
}

export default SigninStack;
