import gql from 'graphql-tag';

const GQL_GET_ME = gql`
  query GetMe {
    me {
      _id
      firstName
      lastName
      fullName
      profileImage
      hasTeams
    }
  }
`;

const GET_ME_TEAMS = gql`
  query GetMeTeams {
    me {
      _id
      teams {
        _id
        name
        hasAccess
        activePackage
        color
        me {
          isAdmin
        }
      }
    }
  }
`;

const GQL_GET_ME_SETTINGS = gql`
  query GetMeSettings {
    me {
      _id
      firstName
      lastName
      profileImage
      credentials {
        email
      }
      teams {
        _id
        name
        numMembers
      }
    }
  }
`;

const UPDATE_PROFILEIMAGEURL = gql`
  mutation UpdateProfileImageUrl($userid: ID!, $profileImageUrl: String!) {
    updateUser(input: { userid: $userid, profileImageUrl: $profileImageUrl }) {
      _id
      firstName
      lastName
      profileImageUrl
    }
  }
`;

const GQL_UPDATE_NAME = gql`
  mutation UpdateUserNames($userid: ID!, $firstName: String!, $lastName: String!) {
    updateUser(input: { userid: $userid, firstName: $firstName, lastName: $lastName }) {
      _id
      firstName
      lastName
      profileImageUrl
    }
  }
`;

const GQL_LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export {
  GQL_GET_ME,
  GET_ME_TEAMS,
  GQL_GET_ME_SETTINGS,
  UPDATE_PROFILEIMAGEURL,
  GQL_UPDATE_NAME,
  GQL_LOGOUT,
};
