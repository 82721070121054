import React, { useState } from 'react';
import { format, occuerencesBetween, isValidDate } from 'services/date';
//import Input from 'components/Input';
import Button from 'components/Button';
import CheckboxButton from '../CheckboxButton';
import DatetimeModal from './DatetimeModal';
import composerStyles from '../ActivityComposer.module.scss';
import styles from './Repeating.module.scss';

export default function Repeating({
  repeating,
  startdate,
  enddate,
  setRepeating,
  setRepeatingEnddate,
  className,
}) {
  const [pickDate, setPickDate] = useState(false);
  return (
    <div className={className}>
      <div className={styles.buttons}>
        <CheckboxButton
          className={styles.repatingButton}
          checked={repeating}
          onClick={() => setRepeating(repeating ? false : true)}
        >
          Repeat weekly
        </CheckboxButton>

        {!!repeating && (
          <>
            <div className={styles.space} />
            <Button
              variant="reset"
              className={composerStyles.button}
              onClick={() => setPickDate(true)}
            >
              {!isValidDate(enddate) ? '+ Pick end date' : format(enddate, 'date')}
            </Button>
          </>
        )}
      </div>

      {repeating && enddate && (
        <div className={styles.summary}>
          Activity is repeated every {format(startdate, 'weekday')} until {format(enddate, 'month')}
          . Creates {occuerencesBetween(startdate, enddate)} activities.
        </div>
      )}

      {pickDate && (
        <DatetimeModal
          title="Pick end date"
          onlyDate
          date={enddate || new Date()}
          setFn={setRepeatingEnddate}
          onClose={() => setPickDate(false)}
        />
      )}
    </div>
  );
}
