import React from 'react';
import Modal from 'components/Modal';
import Box from 'components/Box';
import styles from './ComposerModal.module.scss';

export default function ComposerModal({ onClose, title, children }) {
  return (
    <Modal onClose={onClose}>
      <Box noPadding className={styles.box}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.wrapper}>{children}</div>
      </Box>
    </Modal>
  );
}
