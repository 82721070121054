import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import ListItem from 'components/ListItem';
import Badge from 'components/Badge';
import { dayOfMonth, format, month, withinDays } from 'services/date';
import activityStyles from './Activity.module.scss';
import styles from './ActivityListItem.module.scss';
import ActivityRSVP from './ActivityRSVP';
import ActivityTag from './ActivityTag';

function ListBadge({ activity }) {
  switch (activity.state) {
    case 'cancelled':
      return <Badge text="Cancelled" type="negative" />;
    case 'deleted':
      return <Badge text="DELETED" type="negative" />;
    default:
      return null;
  }
}

const ActivityListItem = ({ activity }) => {
  const showRsvp = withinDays(activity.startdate, 8) && activity.state === 'upcoming';
  const rsvpSlotsLeft = useMemo(() => {
    const attending = activity?.members?.filter((m) => m.attending).length ?? 0;
    return Math.max(activity?.maxAttendees - attending, 0);
  }, [activity?.maxAttendees, activity?.members]);
  return (
    <Box noPadding>
      <Link to={`/schedule/${activity._id}`}>
        <div className={styles.wrapper}>
          <div className={styles.meta}>
            <ActivityTag tag={activity?.tag} />
            {activity.location?.name?.length > 0 && !activity.cancelled && (
              <div className={activityStyles.location}>{activity.location.name}</div>
            )}
          </div>
          <ListItem
            left={
              <div className={styles.date}>
                <div className={styles.monthName}>{month(activity.startdate)}</div>
                <div className={styles.day}>{dayOfMonth(activity.startdate)}</div>
              </div>
            }
            right={<ListBadge activity={activity} />}
            title={activity.title}
            subTitle={
              <div>
                {`${format(activity.startdate, 'long')}`.concat(
                  activity.meetdate ? ` (${format(activity.meetdate, 'clock')})` : '',
                )}
                {activity?.maxAttendees > 0 && (
                  <div className={styles.maxAttendees}>
                    {rsvpSlotsLeft} {rsvpSlotsLeft === 1 ? 'slot left' : 'slots left'}
                  </div>
                )}
                {activity?.rsvpDueDate && (
                  <div className={styles.maxAttendees}>
                    RSVP by {format(activity?.rsvpDueDate, 'long')}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </Link>

      {showRsvp && <ActivityRSVP activity={activity} />}
    </Box>
  );
};

export default ActivityListItem;
