import * as React from 'react';

export default function ListIcon(props) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <path
        d="M3,7h2V9H3V7Zm3,0h14V9H6V7Zm-3,5h2v2H3V12Zm3,0h14v2H6V12Zm-3,5h2v2H3V17Zm3,0h14v2H6V17Z"
        fill="currentColor"
      />
    </svg>
  );
}
