import ToggleMenu from 'components/ToggleMenu';
import React from 'react';

const getToggleText = (key) => {
  switch (key) {
    case true:
      return 'Going';
    case false:
      return 'Not going';
    default:
      return 'No answer';
  }
};

const RSVPDropdown = ({ onChange, attending }) => {
  return (
    <ToggleMenu
      toggleText={getToggleText(attending)}
      menuItems={[
        { content: <button onClick={() => onChange(true)}>Going</button> },
        { content: <button onClick={() => onChange(false)}>Not going</button> },
        { content: <button onClick={() => onChange(null)}>No answer</button> },
      ]}
    />
  );
};

export default RSVPDropdown;
