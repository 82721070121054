import * as React from 'react';

function LocationIcon(props) {
  return (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.68 6.34C.68 3.293 3.225.8 6.335.8s5.653 2.493 5.653 5.54c0 4.155-5.653 10.289-5.653 10.289S.68 10.495.68 6.34zm3.636 0c0 1.108.888 1.979 2.019 1.979 1.13 0 2.019-.87 2.019-1.979 0-1.108-.889-1.978-2.02-1.978-1.13 0-2.018.87-2.018 1.978z"
        fill="#2A3A4A"
      />
    </svg>
  );
}

export default LocationIcon;
