import React, { useEffect, useContext } from 'react';
import AuthContext from '../../contexts/Authentication';

function SignoutScreen() {
  const { signout } = useContext(AuthContext);
  useEffect(() => {
    signout();
  }, []); // eslint-disable-line
  return <div>Signing out</div>;
}

export default SignoutScreen;
