import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Activity from './Activity';
import UpcomingActivities from './UpcomingActivities';
import PastActivities from './PastActivities';
import ActivityComposer from './ActivityComposer';

function ActivitiesScreen() {
  return (
    <Switch>
      <Route path="/schedule/activity-composer/:activityid?" component={ActivityComposer} />
      <Route path="/schedule/past" component={PastActivities} />
      <Route path="/schedule/:activityid" component={Activity} />
      <Route path="/schedule" component={UpcomingActivities} />
    </Switch>
  );
}

export default ActivitiesScreen;
