import fp from 'lodash/fp';
import _ from 'lodash';
import { addDays, addMonths, addHours, setMinutes, setSeconds, parseISO } from 'date-fns';
import { diffMinutes } from 'services/date';

const initialDate = setMinutes(setSeconds(addHours(addDays(new Date(), 1), 1), 0), 0);

const initialState = {
  _id: '',
  title: '',
  other: '',
  location: {},
  opponent: null,
  venue: null,
  startdate: initialDate,
  enddate: addHours(initialDate, 1),
  meetdate: null,
  duration: '1',
  maxAttendees: null,
  rsvpDueDate: null,
  tag: { name: '', color: '' },
  invitedGroups: ['players', 'coaches'],
  invitedMembers: [],
  recurrence: {
    isRecurring: false,
    enddate: addMonths(initialDate, 1),
  },
};

const isDateField = (field) => field.includes('date');

function invitedMembersFn(members) {
  return {
    invitedMembers: _.flatMap(members, (m) => (m.invite === 'invited' ? m.user._id : [])),
  };
}

function durationFn(startdate, enddate) {
  const startEndDiff = diffMinutes(enddate, startdate);
  return {
    enddate: parseISO(enddate),
    duration: startEndDiff === 60 ? '1' : startEndDiff === 90 ? '1.5' : '',
  };
}

function composerReducer(state, action) {
  switch (action.type) {
    case 'set':
      return fp.set(action.field, action.value, state);
    case 'reset':
      return _.reduce(
        action.collection,
        (acc, val, key) => {
          if (key === 'members' && !action.collection.invitedGroups.length > 0) {
            return {
              ...acc,
              ...invitedMembersFn(action.collection.members),
            };
          }
          if (key === 'enddate') {
            return {
              ...acc,
              ...durationFn(action.collection.startdate, action.collection.enddate),
            };
          }
          if (_.has(initialState, key)) {
            return {
              ...acc,
              [key]: isDateField(key) && val ? parseISO(val) : val,
            };
          }
          return acc;
        },
        { ...initialState },
      );
    default:
      return state;
  }
}

export { initialState };

export default composerReducer;
