import gql from 'graphql-tag';

const GET_FILES_UPLOAD_INFO = gql`
  query GetFilesUploadInfo($files: [FileUploadInfoInput]!) {
    uploadInfo {
      files(input: { files: $files }) {
        ... on ImageUploadInfo {
          identifier
          signedUrl
        }
        ... on DocumentUploadInfo {
          identifier
          url
          filename
          documentId
        }
        ... on UnsupportedUploadInfo {
          identifier
          message
        }
      }
    }
  }
`;

const CREATE_POST = gql`
  mutation CreatePost(
    $teamid: String!
    $text: String!
    $attachments: [CreatePostAttachmentInput]!
  ) {
    createPost(input: { teamid: $teamid, text: $text, attachments: $attachments }) {
      node {
        _id
        text
      }
    }
  }
`;

export { GET_FILES_UPLOAD_INFO, CREATE_POST };
