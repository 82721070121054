import React from 'react';
import Loading from 'components/Loading';
import ListItem from 'components/ListItem';
import Box from 'components/Box';
import Button from 'components/Button';
import styles from './styles.module.scss';
import { FindTeam } from './FindTeam';
import { formatCode } from './lib';

function ListSubscriptions({ loading, unused = [], used = [] }) {
  return (
    <>
      <Box className={styles.Hero}>
        <h1>Heja Pro Activation</h1>
        <p>
          To activate Heja Pro for your teams, enter the team code of each team respectively. This
          is so we can connect your payment to each team.
        </p>
        <p>Step by step:</p>
        <ol>
          <li>
            In Heja, copy the team code (located under the team tab), or if you’re not part of the
            team, ask the coach to send it you.
          </li>
          <li>Enter your team code in one of the unused licenses below.</li>
          <li>Repeat for all teams that should have Heja Pro.</li>
        </ol>
        <hr />
        <div className={styles.ManageSubs}>
          <Button href="https://hejahq.onfastspring.com/account/">Manage subscription</Button>
        </div>
      </Box>
      {loading && <Loading />}
      <h3 className={styles.Headline}>Unused licenses</h3>
      {unused?.map((sub) => (
        <Box key={sub.licenseKey}>
          <ListItem title={sub.licenseKey} content={<FindTeam subscription={sub} />} />
        </Box>
      ))}
      {used?.length > 0 && <h3 className={styles.Headline}>Active licenses</h3>}
      {used?.map((sub) => (
        <Box key={sub._id}>
          <ListItem
            title={sub.licenseKey}
            content={
              <div className={styles.ActivateTeam}>
                <div>
                  <div style={{ backgroundColor: sub.teams[0].color }} />
                  <span>
                    {sub.teams[0].name} <em>({formatCode(sub.teams[0].code)})</em>
                  </span>
                </div>
              </div>
            }
          />
        </Box>
      ))}
    </>
  );
}

export default ListSubscriptions;
