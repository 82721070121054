import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SET_ATTENDING } from '../gql';
import ListItem from 'components/ListItem';
import ProfileImage from 'components/ProfileImage';
import Switch from 'components/Switch';
import RSVPDropdown from './RSVPDropdown';
import attendanceCategories from '../attendanceCategories';
import getAttendingAnswer from './getAttendingAnswer';
import useTeam from 'hooks/useTeam';
import styles from './ActivityMemberRow.module.scss';

function getCategory(type, cat) {
  return attendanceCategories[type]?.find(({ category }) => category === cat);
}

function getSubtitle(activity, member) {
  if (activity.state === 'upcoming') {
    const attendingAnswer = getAttendingAnswer(member.attending);
    if (attendingAnswer !== 'no-answer' && member.attendanceDetails?.category) {
      const cat = getCategory(attendingAnswer, member.attendanceDetails?.category);
      return `${cat?.icon} ${
        member.attendanceDetails.note && member.attendanceDetails.note.length > 0
          ? member.attendanceDetails.note
          : cat.category
      }`;
    }
    return member.seen ? 'Seen' : 'Not seen';
  }
  if (member?.attendanceDetails?.note?.length > 0) {
    return `${member?.attendanceDetails?.note}${member.seen ? ' - Seen' : ''}`;
  }
  return member.seen && 'Seen';
}

function ActivityMemberRow({ activity, member, listPosition, className }) {
  const team = useTeam();
  const [mutateAttending, { loading }] = useMutation(SET_ATTENDING);
  const onSelect = (attending) => {
    mutateAttending({
      variables: {
        activityid: activity._id,
        userid: member.user._id,
        attending: attending,
      },
    });
  };

  const subTitle = team.me?.isAdmin && getSubtitle(activity, member);

  return (
    <ListItem
      className={className}
      key={member._id}
      left={<ProfileImage user={member.user} />}
      title={member.user.fullName}
      right={
        team.me?.isAdmin && (
          <div className={styles.right}>
            {!activity.isPast ? (
              <RSVPDropdown onChange={onSelect} loading={loading} attending={member.attending} />
            ) : (
              <Switch onChange={onSelect} checked={member.attending} />
            )}
            {listPosition && listPosition > 0 && (
              <div className={styles.listPosition}>{listPosition}</div>
            )}
          </div>
        )
      }
      subTitle={subTitle}
      borderBottom
    />
  );
}

export default ActivityMemberRow;
