import gql from 'graphql-tag';

const Posts = {
  fragments: {
    imageAttachment: gql`
      fragment ImageAttachment on ImageAttachment {
        _id
        url
      }
    `,
    videoAttachment: gql`
      fragment VideoAttachment on VideoAttachment {
        _id
        url
        thumbnail
      }
    `,
    documentAttachment: gql`
      fragment DocumentAttachment on DocumentAttachment {
        _id
        url
        size
        title
        contentType
        typeIcon
      }
    `,
    comment: gql`
      fragment PostComment on PostComment {
        _id
        text
        publishdate
        creator {
          _id
          fullName
          profileImage
        }
      }
    `,
    postHeader: gql`
      fragment PostHeader on Post {
        _id
        text
        touchdate
        publishdate
        creator {
          _id
          fullName
          firstName
          profileImage
        }
      }
    `,
    reaction: gql`
      fragment Reaction on Reaction {
        _id
        user {
          _id
        }
        reaction
      }
    `,
  },
};

const GET_POSTS = gql`
  query GetPosts($teamid: String!, $cursor: String) {
    posts(input: { teamid: $teamid, cursor: $cursor }) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...PostHeader
          images {
            ... on ImageAttachment {
              ...ImageAttachment
            }
          }
          videos {
            ... on VideoAttachment {
              ...VideoAttachment
            }
          }
          documents {
            ... on DocumentAttachment {
              ...DocumentAttachment
            }
          }
          seenBy {
            seen
            isSeenByParent
            reactions
            user {
              _id
              fullName
              profileImage
            }
          }
          comments(filter: { last: 1 }) {
            ...PostComment
          }
          reactions {
            ...Reaction
          }
        }
      }
    }
  }
  ${Posts.fragments.postHeader}
  ${Posts.fragments.imageAttachment}
  ${Posts.fragments.videoAttachment}
  ${Posts.fragments.documentAttachment}
  ${Posts.fragments.comment}
  ${Posts.fragments.reaction}
`;

const GET_POST = gql`
  query GetPost($teamid: String!, $postid: String!) {
    post(input: { teamid: $teamid, postid: $postid }) {
      ...PostHeader
      seenBy {
        seen
        isSeenByParent
        reactions
        user {
          _id
          fullName
          profileImage
        }
      }
      comments {
        ...PostComment
      }
      reactions {
        ...Reaction
      }
      images {
        ... on ImageAttachment {
          ...ImageAttachment
        }
      }
      documents {
        ... on DocumentAttachment {
          ...DocumentAttachment
        }
      }
      videos {
        ... on VideoAttachment {
          ...VideoAttachment
        }
      }
    }
  }
  ${Posts.fragments.postHeader}
  ${Posts.fragments.imageAttachment}
  ${Posts.fragments.videoAttachment}
  ${Posts.fragments.documentAttachment}
  ${Posts.fragments.comment}
  ${Posts.fragments.reaction}
`;

const DELETE_POST = gql`
  mutation DeletePost($teamid: String!, $postid: String!) {
    deletePost(input: { teamid: $teamid, postid: $postid })
  }
`;

const GET_FILE_DOWNLOAD_URL = gql`
  query FileDownloadUrl($teamid: String!, $filename: String!) {
    signedDownloadUrl(input: { teamid: $teamid, filename: $filename })
  }
`;

const CREATE_COMMENT = gql`
  mutation CreatePostComment($teamid: String!, $postid: String!, $commentText: String!) {
    createComment(input: { teamid: $teamid, postid: $postid, comment: $commentText }) {
      node {
        _id
        comments {
          ...PostComment
        }
      }
    }
  }
  ${Posts.fragments.comment}
`;

const SET_SEENBY = gql`
  mutation SetSeenBy($teamid: String!, $postid: String!) {
    seenPost(input: { teamid: $teamid, postid: $postid }) {
      node {
        _id
        seenBy {
          seen
          isSeenByParent
          reactions
          user {
            _id
            fullName
            profileImage
          }
        }
      }
    }
  }
`;

const SET_POST_REACTION = gql`
  mutation PostReaction(
    $teamid: String!
    $postid: String!
    $reaction: String!
    $add: Boolean
    $remove: Boolean
  ) {
    postReaction(
      input: { teamid: $teamid, postid: $postid, reaction: $reaction, add: $add, remove: $remove }
    ) {
      node {
        _id
        reactions {
          ...Reaction
        }
      }
    }
  }
  ${Posts.fragments.reaction}
`;

export {
  GET_POSTS,
  GET_POST,
  DELETE_POST,
  GET_FILE_DOWNLOAD_URL,
  CREATE_COMMENT,
  SET_SEENBY,
  SET_POST_REACTION,
  Posts,
};
