import _ from 'lodash';

export const TeamCodeRegEx = new RegExp(/^[A-Z]{2}-[0-9]{6}$/);

export const formatCode = _.flow(
  (raw) => raw.toUpperCase(),
  (raw) => raw.trim(),
  (raw) => raw.replace(/[^A-Z0-9]/, ''),
  (raw) => `${raw.substring(0, 2)}-${raw.substring(2)}`,
);
