import fp from 'lodash/fp';

const initialState = {
  text: '',
  attachments: [],
};

function composerReducer(state, action) {
  switch (action.type) {
    case 'set_text':
      return fp.set('text', action.input, state);
    case 'add':
      return fp.set('attachments', state.attachments.concat(action.attachment), state);
    case 'remove':
      return fp.set(
        'attachments',
        state.attachments.filter((a) => a.id !== action.attachment.id),
        state,
      );
    case 'set_attachment_field': {
      /* Input: action.attachment, action.field, action.value */
      return fp.set(
        'attachments',
        state.attachments.reduce((acc, a) => {
          if (a.id === action.attachment.id) {
            return [...acc, { ...a, [action.field]: action.value }];
          }
          return [...acc, a];
        }, []),
        state,
      );
    }
    case 'reset_composer':
      return initialState;
    default:
      return state;
  }
}

export { initialState, composerReducer };
