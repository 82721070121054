import React from 'react';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import styles from './AttendanceTeam.module.scss';
import AttendanceBar from './AttendanceBar';
import AttendanceTeamIcon from './AttendanceTeamIcon';
import { getStatsPercentages } from './utils.js';
import useTeam from 'hooks/useTeam';

function AttendanceType({ title, type, color, loading, data, percentage, selectedType }) {
  if (![type, 'all'].includes(selectedType)) {
    return null;
  }
  const isSelectedType = type === selectedType;
  return (
    <div className={styles.col}>
      {!isSelectedType && <header>{title}</header>}
      <div className={isSelectedType ? styles.expanded : styles.shrunken}>
        <div title={`${data?.attended}/${data?.invited}`}>
          <strong className={styles.large}>{loading ? ' - ' : percentage}%</strong>
        </div>
        <div className={styles.bar}>
          <AttendanceBar loading={loading} foreground={color} completion={percentage} />
        </div>
      </div>
      {isSelectedType && (
        <div className={styles.expanded}>
          <div>Total attendance</div>
          <div>
            <strong>{`${data?.attended}/${data?.invited} attended`}</strong>
          </div>
        </div>
      )}
    </div>
  );
}

function AttendanceTeam({ data, loading, selectedType }) {
  const { practice, game, competition, combined } = getStatsPercentages({ stats: data });
  const { activityTags } = useTeam();
  const mainActivity = activityTags.some((tag) => tag.name === 'Game') ? 'Game' : 'Competition';

  return (
    <Box noPadding>
      <div className={styles.head}>
        <AttendanceTeamIcon className={styles.icon} />
        Team attendance
      </div>
      <div className={styles.wrapper}>
        {mainActivity === 'Game' ? (
          <AttendanceType
            title="Games"
            type="games"
            {...{
              color: '#119FFF',
              loading,
              data: data?.game,
              percentage: game,
              selectedType,
            }}
          />
        ) : (
          <AttendanceType
            title="Competitions"
            type="competitions"
            {...{
              color: '#119FFF',
              loading,
              data: data?.competition,
              percentage: competition,
              selectedType,
            }}
          />
        )}
        <AttendanceType
          title="Practices"
          type="practices"
          {...{
            color: '#FE923E',
            loading,
            data: data?.practice,
            percentage: practice,
            selectedType,
          }}
        />
        <AttendanceType
          title="Combined"
          {...{
            color: '#2C3948',
            loading,
            data: data?.combined,
            percentage: combined,
            selectedType,
          }}
        />
      </div>
      <div className={styles.footer}>
        <Link to="/schedule/past" className={styles.linkToPast}>
          View past activities ⟶
        </Link>
      </div>
    </Box>
  );
}

export default AttendanceTeam;
