import * as React from 'react';

function IconEmail(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 27 22" fill="none" {...props}>
      <path
        d="M25.473.45H1.143a.936.936 0 00-.937.936v18.716a.936.936 0 00.936.936h24.33a.936.936 0 00.937-.936V1.386a.936.936 0 00-.936-.936zM13.882 12.418a.936.936 0 01-1.149 0l-9.16-7.125L4.72 3.816l8.586 6.678 8.587-6.678 1.149 1.477-9.161 7.125z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoIconEmail = React.memo(IconEmail);
export default MemoIconEmail;
