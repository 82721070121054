import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './App';
// import * as serviceWorker from './serviceWorker';

Bugsnag.start({
  apiKey: 'c5cf96ae93c58226e69663595f615fab',
  appVersion: window.appVersion,
  plugins: [new BugsnagPluginReact()],
  onError: () => process.env.NODE_ENV === 'production', // Only send errors in production
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
