import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import useTeam from 'hooks/useTeam';
import Input from 'components/Input';
import Button from 'components/Button';
import CheckboxButton from '../CheckboxButton';
import ComposerModal from './ComposerModal';
import InviteSection from './InviteSection';
import styles from '../ActivityComposer.module.scss';

function summarizeInvitedMembers({ coaches, players, parents, invitedMembers }) {
  const [numCoaches, numPlayers, numParents] = [coaches, players, parents].reduce(
    (acc, membersWithRole) => {
      return [
        ...acc,
        {
          invited: _.intersection(
            invitedMembers,
            membersWithRole.map((m) => m.userid),
          ).length,
          total: membersWithRole.length,
        },
      ];
    },
    [],
  );
  return `Invite: ${[
    numCoaches.invited > 0 && `${numCoaches.invited}/${numCoaches.total} coaches`,
    numPlayers.invited > 0 && `${numPlayers.invited}/${numPlayers.total} players`,
    numParents.invited > 0 && `${numParents.invited}/${numParents.total} parents`,
  ]
    .filter((r) => r)
    .join(', ')}`;
}

export default function Invites({
  invitedGroups,
  toggleInvitedGroup,
  invitedMembers,
  addInvitedMember,
  removeInvitedMember,
  className,
}) {
  const [showInvite, setShowInvite] = useState(false);
  const team = useTeam();
  const handleMemberSwitch = useCallback(
    (userid) => (invited) => {
      invited ? addInvitedMember(userid) : removeInvitedMember(userid);
    },
    [addInvitedMember, removeInvitedMember],
  );
  return (
    <div className={[styles.invitesWrapper, className].join(' ')}>
      <Input.Label>Invites</Input.Label>
      <div className={styles.invites}>
        <CheckboxButton
          checked={invitedGroups.includes('players')}
          onClick={toggleInvitedGroup('players')}
          className={styles.invitedGroup}
        >
          Players
        </CheckboxButton>
        <div className={styles.space} />
        <CheckboxButton
          checked={invitedGroups.includes('coaches')}
          onClick={toggleInvitedGroup('coaches')}
          className={styles.invitedGroup}
        >
          Coaches
        </CheckboxButton>
        <div className={styles.space} />
        <CheckboxButton
          checked={invitedGroups.includes('parents')}
          onClick={toggleInvitedGroup('parents')}
          className={styles.invitedGroup}
        >
          Parents
        </CheckboxButton>
      </div>
      <Button
        type="button"
        variant="round"
        className={styles.btnSelectManually}
        onClick={() => setShowInvite(true)}
      >
        {invitedMembers.length === 0
          ? '+ Select manually'
          : summarizeInvitedMembers({
              coaches: team?.coaches,
              players: team?.players,
              parents: team?.parents,
              invitedMembers,
            })}
      </Button>

      {showInvite && (
        <ComposerModal onClose={() => setShowInvite(false)} title="Invite members">
          <InviteSection
            title="Coaches"
            {...{ members: team?.coaches, invitedMembers, handleMemberSwitch }}
          />
          <InviteSection
            title="Players"
            {...{ members: team?.players, invitedMembers, handleMemberSwitch }}
          />
          <InviteSection
            title="Parents"
            {...{ members: team?.parents, invitedMembers, handleMemberSwitch }}
          />
        </ComposerModal>
      )}
    </div>
  );
}
