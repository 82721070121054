import axios from 'axios';
import { SHA256 } from 'crypto-js';

const deviceToken = SHA256(
  window.navigator.userAgent + window.navigator.appVersion + window.navigator.language,
).toString();

const hejaAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_HOST,
  timeout: 5000,
  headers: {
    'heja-api-version': 3,
    'x-app-version-brand': 'web',
    Accept: 'application/json',
    'x-set-cookie': true,
  },
  withCredentials: true,
});

hejaAuth.interceptors.response.use(async (response) => {
  if (response.data.data) {
    response.data = response.data.data;
  }
  return response;
});

const requestPasscode = async ({ type, value, checkExisting }) =>
  hejaAuth.post('/verificationcode', {
    deviceToken,
    [type]: value,
    checkExisting,
  });

const validatePhoneNumber = (phone) => hejaAuth.post('/validate-phonenumber', { phone });

const loginByCode = (credentialType) => async ({ verificationRef, code }) =>
  hejaAuth.post(`/login/${credentialType}`, {
    deviceToken,
    credentialType,
    code: SHA256(code + process.env.REACT_APP_VERIFICATION_SECRET.toString()).toString(),
    createNew: false,
    verificationRef,
    setCookie: true,
  });

const loginByEmail = loginByCode('email');

const loginByPhone = loginByCode('phone');

const loginByCookie = async () =>
  hejaAuth.post('/login/refreshToken', {
    credentialType: 'refreshToken',
    refreshToken: 'cookie',
    deviceToken,
  });

const loginByFacebook = async ({ facebookToken }) =>
  hejaAuth.post('/login/facebookToken', {
    deviceToken,
    credentialType: 'facebookToken',
    createNew: false,
    facebookToken,
  });

const loginByApple = async ({ firstName, lastName, identityToken }) =>
  hejaAuth.post('/login/apple', {
    credentialType: 'apple',
    deviceToken,
    firstName,
    lastName,
    identityToken,
  });

async function logout() {
  return hejaAuth.post('/logout', {
    credentialType: 'cookie',
    deviceToken,
  });
}

export {
  hejaAuth,
  requestPasscode,
  validatePhoneNumber,
  loginByCode,
  loginByEmail,
  loginByPhone,
  loginByCookie,
  loginByFacebook,
  loginByApple,
  logout,
};
