import * as React from 'react';

function RefreshIcon(props) {
  return (
    <svg viewBox="0 0 65 65" width="1em" height="1em" {...props}>
      <g fill="#2C3948">
        <path d="M32.5 4.999a27.31 27.31 0 00-14.699 4.282l-5.75-5.75v16.11h16.11l-6.395-6.395a21.834 21.834 0 0110.734-2.82c12.171 0 22.073 9.902 22.073 22.074 0 2.899-.577 5.664-1.599 8.202l4.738 2.762A27.299 27.299 0 0060 32.5C60 17.336 47.663 4.999 32.5 4.999zM43.227 51.746c-3.179 1.786-6.826 2.827-10.726 2.827-12.171 0-22.073-9.902-22.073-22.073 0-2.739.524-5.35 1.439-7.771l-4.731-2.851A27.34 27.34 0 005 32.5C5 47.664 17.336 60 32.5 60c5.406 0 10.434-1.584 14.691-4.289l5.758 5.759V45.358H36.838l6.389 6.388z" />
      </g>
    </svg>
  );
}

const MemoRefreshIcon = React.memo(RefreshIcon);
export default MemoRefreshIcon;
