import React from 'react';

function ProPill() {
  return (
    <div
      style={{
        backgroundColor: '#55469F',
        color: '#fff',
        fontSize: '.5rem',
        fontWeight: 500,
        padding: '.1rem .3rem',
        borderRadius: '1rem',
        letterSpacing: '.05rem',
      }}
    >
      PRO
    </div>
  );
}

export default ProPill;
