import React from 'react';
import _ from 'lodash';
import Modal from 'components/Modal';
import Box from 'components/Box';
import emojis from 'assets/emojis';
import styles from './Reaction.module.scss';

const hiddenEmojis = ['sick', 'injured', 'vacation', 'other', 'arriving-late', 'leaving-early'];

function ModalAddEmoji({ onClose, onChoose, hideEmojis }) {
  const rows = _.chain(emojis)
    .map((emoji, key) => ({ emoji, key }))
    .filter(({ key }) => ![].concat(hiddenEmojis, hideEmojis).includes(key))
    .chunk(5)
    .value();
  return (
    <Modal onClose={onClose}>
      <Box noPadding>
        <Box.Title>Add reaction</Box.Title>
        <Box.Content>
          {_.map(rows, (row, i) => (
            <div key={i} className={styles.emojisRow}>
              {_.map(row, ({ emoji, key }) => (
                <button key={key} className={styles.emoji} role="img" onClick={() => onChoose(key)}>
                  {emoji}
                </button>
              ))}
            </div>
          ))}
        </Box.Content>
      </Box>
    </Modal>
  );
}

export default ModalAddEmoji;
