import React from 'react';
import styles from './AppButton.module.scss';
import AddIcon from './AddIcon';
import BackIcon from './BackIcon';
import EditIcon from './EditIcon';
import RefreshIcon from './RefreshIcon';
import CaretIcon from './CaretIcon';
import classNames from 'classnames';

function AppButton({
  forwardedRef,
  text,
  type,
  add,
  close,
  back,
  edit,
  refresh,
  caret,
  rotating,
  onClick,
}) {
  let icon;
  if (add || type === 'add') {
    icon = <AddIcon />;
  }
  if (close || type === 'close') {
    icon = <AddIcon style={{ transform: 'rotate(45deg)' }} />;
  }
  if (back || type === 'back') {
    icon = <BackIcon />;
  }
  if (edit || type === 'edit') {
    icon = <EditIcon />;
  }
  if (refresh || type === 'refresh') {
    icon = <RefreshIcon />;
  }
  if (caret || type === 'caret') {
    icon = <CaretIcon />;
  }
  if (!icon) {
    return null;
  }
  return (
    <button
      ref={forwardedRef}
      className={classNames({
        [styles.wrapper]: true,
        [styles.spin]: rotating,
        [styles.text]: !!text,
      })}
      onClick={onClick}
    >
      {text && <span>{text}</span>}
      {icon}
    </button>
  );
}

export default React.forwardRef((props, ref) => <AppButton {...props} forwardedRef={ref} />);
