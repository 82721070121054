import React, { useState } from 'react';
import { differenceInMinutes, format, isValid } from 'date-fns';
import Input from 'components/Input';
import Button from 'components/Button';
import CheckboxButton from '../CheckboxButton';
import DatetimeModal from './DatetimeModal';
import styles from './DateTime.module.scss';
import MeetDateModal from './MeetDateModal';
import { meetdateOptions } from './MeetDateInput';

export default function DateTime({
  startdate,
  setStartdate,
  enddate,
  setEnddate,
  meetdate,
  setMeetdate,
  duration,
  setDuration,
  className,
}) {
  const [modal, setModal] = useState('');
  return (
    <div className={className}>
      <div className={styles.dates}>
        <div className={styles.startdate}>
          <Input.Label>Start</Input.Label>
          <Button
            type="button"
            variant="reset"
            className={styles.datetimeButton}
            onClick={() => setModal('startdate')}
          >
            {format(startdate, 'yyyy-MM-dd HH:mm')}
          </Button>
        </div>
        <div className={styles.space} />
        {Number(duration) === 0 && (
          <div className={styles.enddate}>
            <Input.Label>End</Input.Label>
            <Button
              type="button"
              variant="reset"
              className={styles.datetimeButton}
              onClick={() => setModal('enddate')}
              disabled={Number(duration) > 0}
            >
              {format(enddate, 'yyyy-MM-dd HH:mm')}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.meetdate}>
        <Input.Label>Meet At</Input.Label>
        <Button
          type="button"
          variant="reset"
          className={styles.datetimeButton}
          onClick={() => setModal('meetdate')}
        >
          {
            meetdateOptions.find((element) =>
              isValid(meetdate)
                ? element.value === differenceInMinutes(startdate, meetdate)
                : meetdateOptions[0],
            ).label
          }
        </Button>
      </div>

      <div className={styles.duration}>
        <Input.Label>Duration</Input.Label>
        <div className={styles.durationButtons}>
          <CheckboxButton
            className={styles.durationButton}
            checked={duration === '1'}
            onClick={() => setDuration('1')}
          >
            1h
          </CheckboxButton>
          <div className={styles.space} />
          <CheckboxButton
            className={styles.durationButton}
            checked={duration === '1.5'}
            onClick={() => setDuration('1.5')}
          >
            1h 30m
          </CheckboxButton>
          <div className={styles.space} />
          <CheckboxButton
            className={styles.durationButton}
            checked={duration === '0'}
            onClick={() => setDuration('0')}
          >
            Custom
          </CheckboxButton>
        </div>
      </div>

      {modal === 'startdate' && (
        <DatetimeModal
          date={startdate}
          setFn={setStartdate}
          onClose={() => setModal('')}
          title="Start date and time"
        />
      )}
      {modal === 'enddate' && (
        <DatetimeModal
          date={enddate}
          setFn={setEnddate}
          onClose={() => setModal('')}
          title="End date and time"
        />
      )}
      {modal === 'meetdate' && (
        <MeetDateModal
          meetdate={meetdate}
          startdate={startdate}
          setFn={setMeetdate}
          onClose={() => setModal('')}
          title="Meet at"
        />
      )}
    </div>
  );
}
