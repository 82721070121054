import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import NonProTeam from './NonProTeam';
import { GET_ME_TEAMS } from 'features/user/gql';

function NonProTeams() {
  const { data } = useQuery(GET_ME_TEAMS);
  const teams = useMemo(() => data?.me?.teams.filter((t) => !t.hasAccess) ?? [], [data]);
  return (
    <>
      {teams.map((team) => (
        <NonProTeam key={team._id} team={team} />
      ))}
    </>
  );
}

export default NonProTeams;
