import React from 'react';
import styles from './PostContent.module.scss';

const URL_REGEX = /(https?:\/\/\S*)\b/;
const Hyperlink = ({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
    {url}
  </a>
);

export default function PostContent({ text }) {
  return (
    <>
      {text.split('\n').map((row, key) => (
        <p className={styles.content} key={key}>
          {row
            .split(URL_REGEX)
            .map((content, key) =>
              URL_REGEX.test(content) ? <Hyperlink key={key} url={content} /> : content,
            )}
        </p>
      ))}
    </>
  );
}
