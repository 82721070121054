import React from 'react';
import styles from './Activity.module.scss';

function ActivityVenue({ tag, venue }) {
  if (tag?.name !== 'Game' || !venue) {
    return null;
  }
  return <div className={styles.venue}>{`${venue} game`.toUpperCase()}</div>;
}

export default ActivityVenue;
