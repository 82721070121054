import React from 'react';
import classNames from 'classnames';
import styles from './Badge.module.scss';

/**
 * @param {Object} obj
 * @param {string} obj.text
 * @param {'solid'|'line'|'negative'} obj.type
 */
const Badge = ({ text, type }) => {
  return (
    <div
      className={[
        styles.badge,
        classNames({
          [styles.solid]: type === 'solid',
          [styles.line]: type === 'line',
          [styles.negative]: type === 'negative',
        }),
      ].join(' ')}
    >
      {text}
    </div>
  );
};

export default Badge;
