import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ListItem from 'components/ListItem';
import styles from './LocationModal.module.scss';
import ComposerModal from './ComposerModal';
import { GET_OPPONENTS } from '../gql';

function Item({ opponent, onSelectOpponent }) {
  const onSelect = useCallback(() => {
    onSelectOpponent({
      _id: opponent._id,
      name: opponent.name,
      contactName: opponent.contactName,
      phone: opponent.phone,
      email: opponent.email,
    });
  }, [opponent, onSelectOpponent]);
  return (
    <ListItem
      className={styles.item}
      title={opponent.name}
      subTitle={opponent.contactName ? `Contact: ${opponent.contactName}` : null}
      onClick={onSelect}
    />
  );
}

function OpponentsModal({ setOpponent, onClose }) {
  const { data, loading } = useQuery(GET_OPPONENTS, {
    fetchPolicy: 'no-cache',
  });

  return (
    <ComposerModal title="Select Opponent" onClose={onClose}>
      <div className={styles.resultWrapper}>
        {data?.opponents.map((opp, ix) => (
          <Item key={`${ix}_${opp.name}`} opponent={opp} onSelectOpponent={setOpponent} />
        ))}
        {!loading && !data?.opponents?.length && (
          <div className={styles.emptyState}>To select an opponent add them in the app first.</div>
        )}
      </div>
    </ComposerModal>
  );
}

export default OpponentsModal;
