import { useMutation } from '@apollo/react-hooks';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import ToggleMenu from 'components/ToggleMenu';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getApolloClient } from '../../../apollo';
import { Activities, CANCEL_ACTIVITY, DELETE_ACTIVITY } from '../gql';

const apollo = getApolloClient();

function updateStatus({ activityid, field, value }) {
  const fragment = apollo.readFragment({
    id: `Activity:${activityid}`,
    fragment: Activities.fragments.activity,
  });
  return apollo.writeFragment({
    id: `Activity:${activityid}`,
    fragment: Activities.fragments.activity,
    data: { ...fragment, state: 'deleted', status: { ...fragment.status, [field]: value } },
  });
}

function EditActivity({ activity }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [mutateDeleteActivity] = useMutation(DELETE_ACTIVITY);
  const [mutateCancelActivity] = useMutation(CANCEL_ACTIVITY);

  const onDeletion = () => setConfirmDelete(true);

  const deleteActivity = (deleteFuture) => () =>
    mutateDeleteActivity({
      variables: {
        activityid: activity._id,
        deleteFuture,
      },
      update: () => {
        updateStatus({ activityid: activity._id, field: 'deleted', value: true });
        setConfirmDelete(false);
      },
    });

  const onCancellation = () => setConfirmCancel(true);

  const cancelActivity = (cancelFuture) => () =>
    mutateCancelActivity({
      variables: { activityid: activity._id, cancelFuture },
      update: () => {
        const fragment = apollo.readFragment({
          id: `Activity:${activity._id}`,
          fragment: Activities.fragments.activity,
        });
        setConfirmCancel(false);
        return apollo.writeFragment({
          id: `Activity:${activity._id}`,
          fragment: Activities.fragments.activity,
          data: { ...fragment, cancelled: true, state: 'cancelled' },
        });
      },
    });

  if (!['upcoming', 'cancelled'].includes(activity?.state)) {
    return null;
  }

  const menuItems = [
    activity?.state === 'upcoming' && {
      content: <Link to={`/schedule/activity-composer/${activity._id}`}>Edit</Link>,
    },
    activity?.state !== 'cancelled' && {
      content: (
        <button type="button" onClick={onCancellation}>
          Cancel
        </button>
      ),
    },
    {
      content: (
        <button type="button" onClick={onDeletion}>
          Delete
        </button>
      ),
      destructive: true,
    },
  ];

  return (
    <div>
      <ToggleMenu toggleIcon="edit" menuItems={menuItems} />

      {confirmDelete &&
        (activity.isRecurring ? (
          <AlertModal
            title="Delete repeating activity"
            body="This is a repeating activity"
            onClose={() => setConfirmDelete(false)}
            buttons={[
              { text: 'Cancel', variant: 'inverted', onClick: () => setConfirmDelete(false) },
              { text: 'Delete only this', onClick: deleteActivity(false) },
              { text: 'Delete all future', onClick: deleteActivity(true) },
            ]}
          />
        ) : (
          <ConfirmModal
            title="Delete this activity"
            body="This cant be undone."
            onConfirm={deleteActivity()}
            onClose={() => setConfirmDelete(false)}
          />
        ))}

      {confirmCancel &&
        (activity.isRecurring ? (
          <AlertModal
            title="Cancel repeating activity"
            body="This is a repeating activity. You can cancel only this, or all future activities."
            onClose={() => setConfirmCancel(false)}
            buttons={[
              { text: 'Cancel', variant: 'inverted', onClick: () => setConfirmCancel(false) },
              { text: 'Cancel only this', onClick: cancelActivity(false) },
              { text: 'Cancel all future', onClick: cancelActivity(true) },
            ]}
          />
        ) : (
          <ConfirmModal
            title="Cancel this activity"
            body="This will mark the activity as cancelled."
            onConfirm={cancelActivity()}
            onClose={() => setConfirmCancel(false)}
          />
        ))}
    </div>
  );
}

export default EditActivity;
