import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import Button from 'components/Button';
import ProfileImage from 'components/ProfileImage';
import { SET_ATTENDING } from '../gql';
import RSVPDetails from './RSVPDetails';
import styles from './RSVPRow.module.scss';
import attendanceCategories from '../attendanceCategories';
import ListItem from 'components/ListItem';
import RSVPDropdown from 'features/activities/Activity/RSVPDropdown';

function RSVPRow({ activityid, member }) {
  const [setMemberAttending, { loading }] = useMutation(SET_ATTENDING);
  const memberHasAnswered = useMemo(() => member.attending === true || member.attending === false, [
    member.attending,
  ]);
  const [rsvpDetails, setRsvpDetails] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);
  const onOpenDetails = useCallback(() => {
    setRsvpDetails(true);
  }, []);
  const setAttending = useCallback(
    (attending) => async () => {
      setLoadingButton(attending ? 'going' : 'not-going');
      try {
        await setMemberAttending({
          variables: {
            activityid,
            userid: member.user._id,
            attending,
          },
        });
        setLoadingButton(null);
      } catch (e) {
        setLoadingButton(null);
        throw e;
      }
    },
    [setMemberAttending, activityid, member],
  );
  const onDropdownChange = (attending) => {
    setMemberAttending({
      variables: {
        activityid,
        userid: member.user._id,
        attending,
      },
    });
  };
  const buttonDetailsText = useMemo(() => {
    if (member.attending !== true && member.attending !== false) {
      return '';
    }
    if (member.attendanceDetails?.note?.length > 0) {
      return member.attendanceDetails?.note;
    }
    if (member.attendanceDetails?.category) {
      return _.upperFirst(
        attendanceCategories[member.attending === true ? 'going' : 'not-going']
          .find(({ category }) => category === member.attendanceDetails.category)
          .category?.replace(/-/g, ' '),
      );
    }
    return member.attending === true ? 'Add note…' : 'Leave reason…';
  }, [member.attendanceDetails, member.attending]);
  return (
    <>
      <div className={styles.wrapper}>
        <ListItem
          left={<ProfileImage user={member.user} />}
          title={member.user.firstName}
          right={
            <div className={styles.reasonButtonRow}>
              {memberHasAnswered ? (
                <>
                  <Button onClick={onOpenDetails} variant="small" className={styles.detailsButton}>
                    {buttonDetailsText}
                  </Button>
                  <RSVPDropdown
                    onChange={onDropdownChange}
                    attending={member.attending}
                    loading={loading}
                  />
                </>
              ) : (
                <>
                  <Button
                    variant="small"
                    disabled={member.attending === true || loading}
                    onClick={setAttending(true)}
                    loading={loadingButton === 'going'}
                  >
                    Going
                  </Button>
                  <Button
                    variant="small"
                    disabled={member.attending === false || loading}
                    onClick={setAttending(false)}
                    loading={loadingButton === 'not-going'}
                  >
                    Not going
                  </Button>
                </>
              )}
            </div>
          }
          style={{ marginBottom: '-0.5rem' }}
        />
      </div>
      {rsvpDetails && (
        <RSVPDetails
          activityid={activityid}
          member={member}
          onClose={() => setRsvpDetails(false)}
        />
      )}
    </>
  );
}

export default RSVPRow;
