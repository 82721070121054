async function jsinject(src, nonce) {
  return new Promise((res, rej) => {
    const script = document.createElement('script');
    script.src = src;
    script.id = new Date().getUTCMilliseconds().toString();
    script.nonce = nonce;
    document.head.appendChild(script);
    script.onload = () => res();
    script.onerror = (e) => rej(e);
  });
}

export default jsinject;
