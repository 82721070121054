import React, { useState } from 'react';
import _ from 'lodash';
import Box from 'components/Box';
import ListItem from 'components/ListItem';
import Switch from 'components/Switch';
import ActivityMemberRow from './ActivityMemberRow';
import getAttendingAnswer from './getAttendingAnswer';
import styles from './ResponsesGroup.module.scss';

const defaultSortBy = 'user.fullName';

function OrderResponses({ sortBy, setSortBy }) {
  return (
    <div className={styles.orderer}>
      Order by response time
      <Switch
        checked={sortBy === 'answeredAt'}
        onChange={(val) => setSortBy(val ? 'answeredAt' : defaultSortBy)}
      />
    </div>
  );
}

function ResponsesGroup({ title, attending, activity, showOrdering }) {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const members = activity.members.filter((m) => getAttendingAnswer(m.attending) === attending);
  if (members.length === 0) {
    return null;
  }
  const { coach, player, parent } = _(members).sortBy(sortBy).groupBy('role').value();
  return (
    <Box>
      <ListItem.Title className={styles.titleWrapper}>
        <div className={styles.title}>
          {title} ({members.length})
        </div>
        {showOrdering && <OrderResponses {...{ sortBy, setSortBy }} />}
      </ListItem.Title>
      {coach && (
        <>
          <div className={styles.groupTitle}>Coaches ({coach.length})</div>
          <div>
            {coach.map((m, i) => (
              <ActivityMemberRow
                className={styles.wrapper}
                key={m._id}
                activity={activity}
                member={m}
                listPosition={sortBy === 'answeredAt' && i + 1}
              />
            ))}
          </div>
        </>
      )}
      {player && (
        <>
          <div className={styles.groupTitle}>Players ({player.length})</div>
          <div>
            {player.map((m, i) => (
              <ActivityMemberRow
                className={styles.wrapper}
                key={m._id}
                activity={activity}
                member={m}
                listPosition={sortBy === 'answeredAt' && i + 1}
              />
            ))}
          </div>
        </>
      )}
      {parent && (
        <>
          <div className={styles.groupTitle}>Parents ({parent.length})</div>
          <div>
            {parent.map((m, i) => (
              <ActivityMemberRow
                className={styles.wrapper}
                key={m._id}
                activity={activity}
                member={m}
                listPosition={sortBy === 'answeredAt' && i + 1}
              />
            ))}
          </div>
        </>
      )}
    </Box>
  );
}

export default ResponsesGroup;
