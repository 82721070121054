import classNames from 'classnames';
import React from 'react';
import IconSpinner from '../IconSpinner';
import styles from './Button.module.scss';

const ButtonOrA = ({ href, children, ...props }) =>
  href ? (
    <a href={href} {...props}>
      {children}
    </a>
  ) : (
    <button {...props}>{children}</button>
  );

/**
 * @param {{
 * loading?: boolean,
 * className?: string,
 * variant?: ('small'|'block'|'round'|'reset'),
 * children: string }
 * & JSX.IntrinsicElements['button'] } props
 */
const Button = ({ loading, variant, href, className, children, ...rest }) => (
  <ButtonOrA
    href={href}
    disabled={rest.disabled || loading}
    type={rest.type || 'button'}
    className={[
      classNames({
        [styles.button]: true,
        [styles.small]: variant === 'small',
        [styles.block]: variant === 'block',
        [styles.round]: variant === 'round',
        [styles.reset]: variant === 'reset',
        [styles.inverted]: variant === 'inverted',
        [styles.destructive]: variant === 'destructive',
      }),
      className,
    ].join(' ')}
    {...rest}
  >
    {loading ? <IconSpinner className={styles.spin} /> : children}
  </ButtonOrA>
);

export default Button;
