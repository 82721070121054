import MemoIconLink from 'components/IconLink';
import IconSpinner from 'components/IconSpinner';
import React, { useEffect, useState } from 'react';
import { getLinkPreview } from 'services/links';
import styles from './LinkPreview.module.scss';

export default function LinkPreview({ url }) {
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState({});
  useEffect(() => {
    getLinkPreview(url)
      .then((data) => {
        setPreview(data);

        if (data?.image) {
          // preload image
          try {
            const img = new Image();
            img.src = data.image;
            return new Promise((resolve) => (img.onload = resolve));
          } catch {}
        }
      })
      .finally(() => setLoading(false));
  }, [url]);

  if (!loading && !preview) {
    return null;
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={[styles.wrapper, loading && styles.loading].join(' ')}
    >
      <div className={styles.image} style={{ backgroundImage: `url(${preview?.image})` }}>
        <IconSpinner width="1.5em" height="1.5em" className={styles.spin} />
        {!loading && preview?.image == null && <MemoIconLink />}
      </div>
      <div className={styles.title}>{preview?.title}</div>
      <div className={styles.siteName}>{preview?.siteName}</div>
    </a>
  );
}
