import React from 'react';
import styles from './RSVPRow.module.scss';

function Reason({ category, icon, selected, onClick }) {
  return (
    <div className={[styles.reason, selected && styles.reasonSelected].join(' ')} onClick={onClick}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.text}>{category.replace(/-/g, ' ')}</div>
    </div>
  );
}

export default Reason;
