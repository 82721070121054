import React from 'react';

export const meetdateOptions = [
  { label: 'No meet time', value: null },
  { label: 'Same as start time', value: 0 },
  { label: '5 minutes before', value: 5 },
  { label: '10 minutes before', value: 10 },
  { label: '15 minutes before', value: 15 },
  { label: '20 minutes before', value: 20 },
  { label: '25 minutes before', value: 25 },
  { label: '30 minutes before', value: 30 },
  { label: '45 minutes before', value: 45 },
  { label: '60 minutes before', value: 60 },
  { label: '90 minutes before', value: 90 },
  { label: '120 minutes before', value: 120 },
];

export default function MeetDateInput({ value, onChange }) {
  return (
    <select value={value} onChange={(e) => onChange(e.target.value)}>
      {meetdateOptions.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
