import gql from 'graphql-tag';
import { Activities } from '../gql';

const CREATE_ACTIVITY = gql`
  mutation CreateActivity(
    $teamid: String!
    $title: String!
    $startdate: Date!
    $enddate: Date!
    $meetdate: Date
    $opponent: ActivityOpponentInput
    $other: String
    $timezone: String
    $location: ActivityLocationInput
    $invitedGroups: [String]
    $invites: [String]
    $venue: String
    $tag: ActivityTagInput
    $recurrence: InputActivityRecurrence
    $maxAttendees: Int
    $rsvpDueDate: Date
  ) {
    createActivity(
      input: {
        teamid: $teamid
        title: $title
        startdate: $startdate
        enddate: $enddate
        meetdate: $meetdate
        opponent: $opponent
        other: $other
        timezone: $timezone
        location: $location
        invitedGroups: $invitedGroups
        invites: $invites
        venue: $venue
        tag: $tag
        recurrence: $recurrence
        maxAttendees: $maxAttendees
        rsvpDueDate: $rsvpDueDate
      }
    )
  }
`;

const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity(
    $activityid: String!
    $teamid: String!
    $title: String!
    $startdate: Date!
    $enddate: Date!
    $meetdate: Date
    $other: String
    $opponent: ActivityOpponentInput
    $timezone: String
    $location: ActivityLocationInput
    $invitedGroups: [String]
    $invites: [String]
    $venue: String
    $tag: ActivityTagInput
    $updateFuture: Boolean
    $maxAttendees: Int
    $rsvpDueDate: Date
  ) {
    updateActivity(
      input: {
        activityid: $activityid
        teamid: $teamid
        title: $title
        startdate: $startdate
        enddate: $enddate
        meetdate: $meetdate
        opponent: $opponent
        other: $other
        timezone: $timezone
        location: $location
        invitedGroups: $invitedGroups
        invites: $invites
        venue: $venue
        tag: $tag
        updateFuture: $updateFuture
        maxAttendees: $maxAttendees
        rsvpDueDate: $rsvpDueDate
      }
    ) {
      node {
        ...Activity
      }
    }
  }
  ${Activities.fragments.activity}
`;

const GET_LAST_USED_LOCATIONS = gql`
  query GetLastUsedLocations($teamid: String!) {
    lastUsedLocations(input: { teamid: $teamid }) {
      name
      address
      country
      city
      longitude
      latitude
    }
  }
`;

const SEARCH_LOCATION = gql`
  query SearchLocation($teamid: String!, $query: String!) {
    searchLocations(input: { teamid: $teamid, query: $query }) {
      name
      address
      country
      city
      longitude
      latitude
    }
  }
`;

const GET_OPPONENTS = gql`
  query Opponents($teamid: String!) {
    opponents(input: { teamid: $teamid }) {
      _id
      name
      contactName
      phone
      email
    }
  }
`;

export {
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  GET_LAST_USED_LOCATIONS,
  SEARCH_LOCATION,
  GET_OPPONENTS,
};
