import Box from 'components/Box';
import ListItem from 'components/ListItem';
import useTeam from 'hooks/useTeam';
import imageUrl from 'services/images';
import React from 'react';
import TeamMember from './TeamMember';
import styles from './TeamMemberList.module.scss';
import classNames from 'classnames';

function TeamMemberList({ small = false }) {
  const team = useTeam();

  return (
    <div className={classNames({ [styles.padding]: !small })}>
      <Box
        style={{
          backgroundColor: team?.color,
          color: '#fff',
          backgroundImage: team?.image
            ? `url(${imageUrl({
                url: team.image,
                width: 350,
                effect: `tint:100:${team.color?.replace('#', '') ?? ''}`,
              })})`
            : 'none',
          position: 'relative',
          paddingTop: team?.image ? '11rem' : '1rem',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <div className={!team.image && styles.columnTeamBox}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: `linear-gradient(0deg, ${team?.color} 10%, transparent 100%)`,
              zIndex: 1,
            }}
          />
          <strong style={{ position: 'relative', zIndex: 2 }}>{team?.name}</strong>

          <div className={styles.teamCode}>
            <div className={styles.teamCode__title}>Team code</div>
            <div className={styles.teamCode__code}>{team?.code}</div>
          </div>
        </div>
      </Box>

      {team?.coaches?.length > 0 && (
        <Box>
          <ListItem.Title>Coaches</ListItem.Title>
          {team.coaches.map((coach, i) => (
            <TeamMember
              key={coach._id}
              member={coach}
              borderBottom={team.coaches.length !== i + 1}
            />
          ))}
        </Box>
      )}
      {team?.players?.length > 0 && (
        <Box>
          <ListItem.Title>Players</ListItem.Title>
          {team.players.map((player, i) => (
            <TeamMember
              key={player._id}
              member={player}
              borderBottom={team.players.length !== i + 1}
            />
          ))}
        </Box>
      )}
      {team?.parents?.length > 0 && (
        <Box>
          <ListItem.Title>Parents</ListItem.Title>
          {team.parents.map((parent, i) => (
            <TeamMember
              key={parent._id}
              member={parent}
              borderBottom={team.parents.length !== i + 1}
            />
          ))}
        </Box>
      )}
    </div>
  );
}

export default TeamMemberList;
