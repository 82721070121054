import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import Input from 'components/Input';
import ListItem from 'components/ListItem';
import ActivityIndicator from 'components/ActivityIndicator';
import styles from './LocationModal.module.scss';
import ComposerModal from './ComposerModal';
import { GET_LAST_USED_LOCATIONS, SEARCH_LOCATION } from '../gql';

export function getDisplay(location) {
  return _([location.address, location.city, location.state, location.country])
    .compact()
    .join(', ');
}

function Item({ location, onSelectLocation }) {
  const onSelect = useCallback(() => {
    onSelectLocation(location);
  }, [location, onSelectLocation]);
  return (
    <ListItem
      className={styles.item}
      title={location.name}
      subTitle={getDisplay(location)}
      onClick={onSelect}
    />
  );
}

function filterLastUsed(search, list) {
  return search && search.length > 2 ? list.filter((l) => l.name.indexOf(search) > -1) : list;
}

let searchTimeout;
const DEFER_SEARCH_MS = 500;

function LocationModal({ setLocation, onClose }) {
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const { data: lastUsed } = useQuery(GET_LAST_USED_LOCATIONS, { fetchPolicy: 'no-cache' });
  const [searchLocations, { loading, data: searched }] = useLazyQuery(SEARCH_LOCATION);

  useEffect(() => {
    clearTimeout(searchTimeout);
    if (query.length > 2) {
      setIsSearching(true);
      searchTimeout = setTimeout(() => {
        setIsSearching(false);
        searchLocations({ variables: { query } });
      }, DEFER_SEARCH_MS);
    } else {
      setIsSearching(false);
    }
  }, [query, searchLocations]);

  const lastUsedList = useMemo(() => filterLastUsed(query, lastUsed?.lastUsedLocations ?? []), [
    query,
    lastUsed,
  ]);

  const data = useMemo(() => {
    return query.length === 0
      ? lastUsedList
      : [...lastUsedList, ...(searched?.searchLocations ?? [])];
  }, [lastUsedList, query.length, searched]);

  return (
    <ComposerModal title="Add location" onClose={onClose}>
      <div className={styles.searchBar}>
        <Input value={query} onChange={setQuery} autoFocus className={styles.searchInput} />
        {(isSearching || loading) && <ActivityIndicator diameter={35} />}
      </div>
      <div className={styles.resultWrapper}>
        {query.length === 0 && (
          <ListItem.Title className={styles.title}>Used recently</ListItem.Title>
        )}
        {query.length > 0 && <ListItem.Title />}
        {data.map((loc, ix) => (
          <Item key={`${ix}_${loc.name}`} location={loc} onSelectLocation={setLocation} />
        ))}
      </div>
    </ComposerModal>
  );
}

export default LocationModal;
