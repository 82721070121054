import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import { format, sameDay } from 'services/date';
import Alert from 'components/Alert';
import AppButton from 'components/AppButton';
import Box from 'components/Box';
import LayoutHeader from 'components/LayoutHeader';
import ListItem from 'components/ListItem';
import Loading from 'components/Loading';
import Badge from 'components/Badge';
import { GET_ACTIVITY } from '../gql';
import ActivityRSVP from './ActivityRSVP';
import ActivityTag from './ActivityTag';
import ClockIcon from './ClockIcon';
import EditActivityMenu from './EditActivityMenu';
import LocationIcon from './LocationIcon';
import ResponsesGroup from './ResponsesGroup';
import useTeam from 'hooks/useTeam';
import styles from './Activity.module.scss';
import ActivityVenue from './ActivityVenue';
import ListIcon from './ListIcon';

function formatEnddate(startdate, enddate) {
  if (sameDay(startdate, enddate)) {
    return `Ends at ${format(enddate, 'clock')}`;
  }
  return format(enddate, 'long');
}

function formatSubtitle(startdate, enddate, meetdate) {
  if (meetdate) {
    return `Meet at ${format(meetdate, 'clock')}. `.concat(formatEnddate(startdate, enddate));
  }

  return formatEnddate(startdate, enddate);
}

function getBadge(activity, isAdmin) {
  if (activity?.state === 'cancelled') {
    return <Badge text="Cancelled" type="negative" />;
  }
  if (isAdmin && activity?.isRecurring) {
    return <Badge text="Repeating" type="solid" />;
  }
  return null;
}

function Activity() {
  const { activityid } = useParams();
  const history = useHistory();
  const team = useTeam();
  const { data, loading } = useQuery(GET_ACTIVITY, {
    returnPartialData: true,
    variables: { activityid },
  });
  const activity = useMemo(() => data && data.activity?.node, [data]);
  const badge = getBadge(activity, team.me?.isAdmin);
  const rsvpSlotsLeft = useMemo(() => {
    const attending = activity?.members?.filter((m) => m.attending).length ?? 0;
    return Math.max(activity?.maxAttendees - attending, 0);
  }, [activity?.maxAttendees, activity?.members]);

  if (!activity && loading) {
    return <Loading />;
  }
  return (
    <>
      <LayoutHeader
        helmetTitle="Schedule"
        left={<AppButton back onClick={() => history.goBack()} />}
        right={team.me?.isAdmin && <EditActivityMenu activity={activity} />}
      />
      {activity?.state === 'deleted' && <Alert type="negative">Activity has been deleted.</Alert>}
      <Box noPadding>
        <div className={styles.wrapper}>
          <div style={{ display: 'flex', 'flex-direction': 'row' }}>
            <ActivityTag tag={activity?.tag} />
            <ActivityVenue tag={activity?.tag} venue={activity.venue} />
          </div>
          <div className={styles.top}>
            <h1 className={styles.title}>{activity?.title}</h1>
            {badge && (
              <div className={styles.badgeWrapper}>
                <div>{badge}</div>
              </div>
            )}
          </div>
          {activity?.other && (
            <div className={styles.other}>
              {activity.other.split('\n').map((row, key) => (
                <p key={key}>{row}</p>
              ))}
            </div>
          )}
          <ListItem
            left={
              <div className={styles.iconWrapper}>
                <ClockIcon />
              </div>
            }
            title={format(activity?.startdate, 'long')}
            subTitle={formatSubtitle(activity?.startdate, activity?.enddate, activity?.meetdate)}
          />
          {activity?.location?.name?.length > 0 && (
            <ListItem
              left={
                <div className={styles.iconWrapper}>
                  <LocationIcon />
                </div>
              }
              title={activity.location.name}
            />
          )}
          {activity?.maxAttendees > 0 && (
            <ListItem
              left={
                <div className={styles.iconWrapper}>
                  <ListIcon />
                </div>
              }
              title={`${rsvpSlotsLeft} / ${activity.maxAttendees} slots left`}
            />
          )}
          {activity?.rsvpDueDate && (
            <ListItem
              left={
                <div className={styles.iconWrapper}>
                  <ListIcon />
                </div>
              }
              title={`RSVP by ${format(activity?.rsvpDueDate, 'long')}`}
            />
          )}
        </div>
        {activity?.state === 'upcoming' && <ActivityRSVP activity={activity} />}
      </Box>
      <ResponsesGroup
        title={activity?.isPast ? 'Attended' : 'Going'}
        attending="going"
        activity={activity}
        showOrdering={team.me?.isAdmin}
      />
      <ResponsesGroup
        title={activity?.isPast ? 'Didn’t attend' : 'Not going'}
        attending="not-going"
        activity={activity}
      />
      <ResponsesGroup title="No answer" attending="no-answer" activity={activity} />
    </>
  );
}

export default Activity;
