import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { GET_PROSUBSCRIPTIONS_BY_SUBSCRIPTIONID } from './gql';
import ListSubscriptions from './ListSubscriptions';

function ActivateFastspringViaSubscription() {
  const { subscriptionid } = useParams();
  const { data, loading } = useQuery(GET_PROSUBSCRIPTIONS_BY_SUBSCRIPTIONID, {
    variables: {
      subscriptionid,
    },
  });
  const [used, unused] = useMemo(
    () => _.partition(data?.getSubscriptionsWithSubscriptionID, (sub) => sub.teams.length),
    [data],
  );
  return <ListSubscriptions loading={loading} used={used} unused={unused} />;
}

export default ActivateFastspringViaSubscription;
