import React, { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import Reaction from './Reaction';
import useUser from 'hooks/useUser';
import { SET_POST_REACTION } from '../gql';
import AddReactionIcon from './AddReactionIcon';
import styles from './Reaction.module.scss';
import ModalAddReaction from './ModalAddReaction';

function PostReactions({ postid, reactions }) {
  const user = useUser();
  const userReactions = useMemo(() => reactions?.filter((r) => r.user._id === user._id) ?? [], [
    reactions,
    user._id,
  ]);
  const [showModal, setShowModal] = useState(false);
  const groupedReactions = useMemo(() => _.groupBy(reactions, (r) => r.reaction), [reactions]);
  const [mutateReaction] = useMutation(SET_POST_REACTION);
  const toggleReaction = useCallback(
    (reaction) => {
      const toggle = userReactions.some((r) => r.reaction === reaction)
        ? { remove: true }
        : { add: true };
      mutateReaction({ variables: { postid, reaction, ...toggle } });
    },
    [mutateReaction, postid, userReactions],
  );
  return (
    <>
      {_.map(groupedReactions, (reactions, reaction) => (
        <Reaction
          key={reaction}
          postid={postid}
          reaction={reaction}
          reactions={reactions}
          toggleReaction={toggleReaction}
        />
      ))}
      <button
        className={[styles.reaction, styles.addReaction].join(' ')}
        onClick={() => setShowModal(true)}
      >
        <AddReactionIcon className={styles.addIcon} /> +
      </button>
      {showModal && (
        <ModalAddReaction
          onClose={() => setShowModal(false)}
          onChoose={toggleReaction}
          hideEmojis={userReactions?.map(({ reaction }) => reaction)}
        />
      )}
    </>
  );
}

export default PostReactions;
