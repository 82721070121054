import React from 'react';
import ListItem from 'components/ListItem';
import ProfileImage from 'components/ProfileImage';
import Switch from 'components/Switch';

export default function InviteSection({ title, members, invitedMembers, handleMemberSwitch }) {
  if (!members || members.length === 0) {
    return null;
  }
  return (
    <>
      <ListItem.Title>{title}</ListItem.Title>
      {members.map((member, i) => (
        <ListItem
          key={member._id}
          title={member.fullName}
          left={<ProfileImage user={member} size={28} />}
          right={
            <Switch
              checked={invitedMembers.includes(member.userid)}
              onChange={handleMemberSwitch(member.userid)}
            />
          }
          borderBottom={i !== members.length - 1}
        />
      ))}
    </>
  );
}
