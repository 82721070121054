import React from 'react';
import StandardPill from 'components/StandardPill';
import styles from './NonProTeam.module.scss';

function PremiumBadge({ packageId }) {
  switch (packageId) {
    case 'standard':
      return <StandardPill />;
    case 'pro':
    case 'loyalty-pro':
      return <div>Pro</div>;
    case 'free':
      return <div className={styles.freePill}>Free</div>;
    default:
      return <div />;
  }
}

export default PremiumBadge;
