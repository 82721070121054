import React from 'react';
import styles from '../ActivityComposer.module.scss';

export default function Tags({ activityTags, setTag, tag, className }) {
  return (
    <div className={[styles.tags, className].join(' ')}>
      {activityTags?.map((t) => (
        <button
          type="button"
          key={t.name}
          onClick={() => setTag(t)}
          className={[styles.tagButton, t.name === tag.name && styles.tagSelected].join(' ')}
          style={{
            backgroundColor: t.name === tag.name ? tag.color : undefined,
            ...(t.name !== tag.name && { color: t.color }),
          }}
        >
          {t.name}
        </button>
      ))}
    </div>
  );
}
