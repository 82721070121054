import Input from 'components/Input';
import React from 'react';
import styles from './Venue.module.scss';

const venues = [
  {
    value: 'home',
    label: 'Home',
  },
  {
    value: 'away',
    label: 'Away',
  },
  {
    value: null,
    label: 'Undecided',
  },
];

export default function Venue({ venue: value, setVenue, className }) {
  return (
    <div className={[className, styles.wrapper].join(' ')}>
      <Input.Label>Venue</Input.Label>
      <div className={styles.buttons}>
        {venues.map((venue) => (
          <button
            type="button"
            key={venue.value}
            className={`${styles.button} ${venue.value === value ? styles.isActive : ''}`}
            onClick={() => setVenue(venue.value)}
          >
            {venue.label}
          </button>
        ))}
      </div>
    </div>
  );
}
