import { format } from 'services/date';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '../Button';
import ListItem from '../ListItem';
import ProfileImage from '../ProfileImage';
import styles from './Comments.module.scss';

const Comments = ({ comments, postid }) => {
  const location = useLocation();
  const history = useHistory();

  const isFeed = location.pathname === '/posts';
  const showExpandComments = isFeed && comments.length > 0;

  return (
    <>
      {comments.map((comment) => (
        <ListItem
          key={comment._id}
          left={<ProfileImage user={comment.creator} />}
          title={comment.creator.fullName}
          subTitle={format(comment.publishdate)}
          className={styles.commentItem}
          content={<p className={styles.comment}>{comment.text}</p>}
        />
      ))}
      {showExpandComments && (
        <Button
          variant="reset"
          onClick={() => {
            history.push(`/posts/${postid}`);
          }}
          className={styles.showMoreButton}
        >
          ↳ All comments
        </Button>
      )}
    </>
  );
};

export default Comments;
