import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import Loading from 'components/Loading';
import LayoutHeader from 'components/LayoutHeader';
import AppButton from 'components/AppButton';
import ActivityListItem from 'features/activities/Activity/ActivityListItem';
import { GET_ACTIVITIES_LIST } from './gql';
import TypeSwitcher from './TypeSwitcher';
import useTeam from 'hooks/useTeam';

function UpcomingActivities() {
  const history = useHistory();
  const team = useTeam();
  const { data, fetchMore, loading } = useQuery(GET_ACTIVITIES_LIST, {
    variables: { teamid: team._id, past: true },
  });
  const showFetchMore = useMemo(() => data && data.activities?.pageInfo?.hasNextPage, [data]);
  const onClick = () => {
    fetchMore({
      variables: { cursor: data.activities.pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult: result }) => {
        const newEdges = result.activities.edges;
        const newPageInfo = result.activities.pageInfo;
        return newEdges.length > 0
          ? {
              activities: {
                ...previousResult.activities,
                edges: previousResult.activities.edges.concat(newEdges),
                pageInfo: {
                  ...previousResult.activities.pageInfo,
                  endCursor: newPageInfo.endCursor,
                  hasNextPage: newPageInfo.hasNextPage,
                },
              },
            }
          : previousResult;
      },
    });
  };
  return (
    <>
      <LayoutHeader
        helmetTitle="Schedule"
        title={
          <div style={{ marginBottom: '1rem' }}>
            <h2>Schedule</h2>
            <TypeSwitcher title="Past" onClick={() => history.push('/schedule')} />
          </div>
        }
        right={
          team.me?.isAdmin && (
            <AppButton add onClick={() => history.push('/schedule/activity-composer')} />
          )
        }
      />
      {loading && <Loading />}
      {!loading && data?.activities?.edges.length === 0 && <div>No past activities yet.</div>}
      {data?.activities?.edges?.map((e) => (
        <ActivityListItem key={e.node._id} activity={e.node} />
      ))}
      {showFetchMore && (
        <Button variant="block" onClick={onClick}>
          Load more
        </Button>
      )}
    </>
  );
}

export default UpcomingActivities;
