import axios from 'axios';
import Resizer from 'react-image-file-resizer';

const instance = axios.create({});
const IMAGE_URL = process.env.REACT_APP_IMAGE_SERVICE_URL;

function getFileBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsArrayBuffer(file);
  });
}

function resizeImage(file, width, height) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });
}

async function imageUploader({ file, uploadInfo: { identifier, signedUrl }, onProgress }) {
  const compressedFile = await resizeImage(file, 1000, 1000);
  const buffer = await getFileBuffer(compressedFile);

  await instance.put(signedUrl, buffer, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
    onUploadProgress: onProgress,
  });

  return {
    data: {
      secure_url: `${IMAGE_URL}/${identifier}`,
    },
  };
}

async function documentUploader({ file, uploadInfo: { url }, onProgress }) {
  return instance.put(url, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    onUploadProgress: onProgress,
  });
}

const exportUploader = {
  images: imageUploader,
  documents: documentUploader,
};

export default exportUploader;
