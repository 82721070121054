import * as React from 'react';

function IconMenu(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 12.032a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM3 17.064a1 1 0 100 2h18a1 1 0 000-2H3z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconMenu = React.memo(IconMenu);
export default MemoIconMenu;
