import _ from 'lodash';

const IMAGE_URL = process.env.REACT_APP_IMAGE_SERVICE_URL;
const CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUDNAME;

const SIZES = [24, 48, 64, 96, 128, 200, 304, 400, 600, 800, 1000, 1200, 2048];

function parseUrl(urlProp) {
  const url = new URL(urlProp);
  const isCloudinaryUrl = url.hostname === 'res.cloudinary.com';
  const hejaBaseUrl = new URL(IMAGE_URL);

  const isHejaUrl = url.hostname === hejaBaseUrl.hostname;
  const split = url.pathname.split('/');
  const imageId = split.slice(-1)[0].split('.')[0];
  const isTeamApp = split[1] === 'teamapp';

  if (imageId.length === 0) {
    throw new Error('URL missing id');
  }

  return {
    isCloudinaryUrl,
    isHejaUrl,
    imageId,
    isTeamApp,
  };
}

function imageUrl({ url: urlProp, crop, effect, gravity, width, faceDetection, maxRes }) {
  let parsedUrl;
  try {
    parsedUrl = parseUrl(urlProp);
  } catch (error) {
    return urlProp;
  }

  const { isCloudinaryUrl, isHejaUrl, imageId, isTeamApp } = parsedUrl;
  const newWidth = SIZES.find((s) => s >= width) || SIZES[SIZES.length - 1];
  if (isCloudinaryUrl) {
    return cloudinaryUrl({
      imageId,
      crop,
      effect,
      gravity,
      width: newWidth,
      isTeamApp,
    });
  }

  if (isHejaUrl) {
    return hejaUrl({
      imageId,
      width: newWidth,
      faceDetection,
      maxRes,
    });
  }

  return urlProp;
}

function hejaUrl({ imageId, width, height, faceDetection, maxRes }) {
  const searchParams = new URLSearchParams();

  if (width) {
    searchParams.append('width', width);
  }
  if (height) {
    searchParams.append('height', height);
  }
  if (faceDetection) {
    searchParams.append('faceDetection', faceDetection);
  }
  if (maxRes) {
    searchParams.append('maxRes', maxRes);
  }

  const url = new URL(IMAGE_URL);
  url.pathname = `/${imageId}`;
  url.search = searchParams;

  return url.toString();
}

function cloudinaryUrl({ imageId, crop, effect, gravity, width, height, isTeamApp }) {
  const cloudname = isTeamApp ? 'teamapp' : CLOUD_NAME;
  const params = [
    'f_auto',
    'q_auto',
    crop ? `c_${crop}` : '',
    effect ? `e_${effect}` : '',
    gravity ? `g_${gravity}` : '',
    width ? `w_${width}` : '',
    height ? `h_${height}` : '',
  ]
    .filter(Boolean)
    .join(',');

  const url = new URL(IMAGE_URL);
  url.pathname = `/${cloudname}/image/upload/${params}/${imageId}`;

  return url.toString();
}

export default _.memoize(imageUrl);
