import React, { useCallback, useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from './Opponent.module.scss';
import OpponentsModal from './OpponentsModal';

function Opponent({ opponent, setOpponent, className }) {
  const [showModal, setShowModal] = useState(false);
  const onSetOpponent = useCallback(
    (opponent) => {
      setOpponent(opponent);
      setShowModal(false);
    },
    [setOpponent, setShowModal],
  );

  return (
    <div className={className}>
      <div className={styles.startdate}>
        <Input.Label>Opponent</Input.Label>
        <Button
          type="button"
          variant="reset"
          className={styles.opponentButton}
          onClick={() => setShowModal(true)}
        >
          {opponent?.name ? (
            <div className={styles.selectedLocation}>
              <div className={styles.name}>{opponent.name}</div>
              <div className={styles.info}>{opponent.contactName}</div>
            </div>
          ) : (
            <div className={styles.placeholder}>Select opponent...</div>
          )}
        </Button>
      </div>
      {showModal && (
        <OpponentsModal onClose={() => setShowModal(false)} setOpponent={onSetOpponent} />
      )}
    </div>
  );
}

export default Opponent;
