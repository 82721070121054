import React from 'react';
import ReactDOM from 'react-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styles from './ErrorMessage.module.scss';

const errorRoot = document.getElementById('error-root');

const ERROR_MESSAGE = gql`
  query GetErrorMessage {
    errorMessage @client
  }
`;

const RESET_ERROR_MESSAGE = gql`
  mutation RestErrorMessage {
    setErrorMessage(message: "") @client
  }
`;

function ErrorMessage() {
  const {
    data: { errorMessage },
  } = useQuery(ERROR_MESSAGE);
  const [resetError] = useMutation(RESET_ERROR_MESSAGE);
  if (!errorMessage.length > 0) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className={styles.error}>
      <p>
        <strong>{errorMessage}</strong>
      </p>
      <button className={styles.btnClose} onClick={resetError}>
        ×
      </button>
    </div>,
    errorRoot,
  );
}

export default ErrorMessage;
