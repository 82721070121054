import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_ANALYTICS_URL,
});

const trackQueue = new Set(JSON.parse(window.localStorage.getItem('trackQueue')));

function persistQueue() {
  window.localStorage.setItem('trackQueue', JSON.stringify([...trackQueue], null, 2));
}

function addToQueue(data) {
  trackQueue.add(data);
  persistQueue();
}

async function flush() {
  const eventsToSend = [];
  trackQueue.forEach((value) => {
    eventsToSend.push(value);
    trackQueue.delete(value);
  });
  if (eventsToSend.length === 0) {
    return;
  }
  try {
    await instance.post('/track', eventsToSend);
    persistQueue();
  } catch (e) {
    // If failed, move back to queue
    eventsToSend.forEach((c) => addToQueue(c));
    persistQueue();
  }
}

let t = () => {
  clearTimeout(t);
  return setTimeout(async () => {
    await flush();
    t();
  }, 5000);
};

t();

export async function trackPage(pageName, { user_id, team_id }) {
  addToQueue({
    type: 'Screen',
    name: pageName,
    context: {
      web: 1,
      useragent: window.navigator.userAgent,
      teamid: team_id,
      userid: user_id,
    },
    timestamp: new Date().toUTCString(),
  });
}
