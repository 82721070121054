import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import IconApple from './IconApple';
import IconEmail from './IconEmail';
import IconPhone from './IconPhone';
import styles from './SigninScreen.module.scss';
import useAppleSignin from './useAppleSignin';

function Signin() {
  const history = useHistory();
  const { signIn } = useAppleSignin();

  return (
    <>
      <h1 className={styles.title}>
        Sign in to <span>Heja Desktop</span>
      </h1>

      <p>
        Heja Desktop is a part of our <a href="https://pro.heja.io/paid-plans">Pro plan</a>.
        Download the Heja app to upgrade or to set up a new team.
      </p>

      <div className={styles.buttons}>
        <Button
          variant="block"
          className={styles.buttonPhone}
          onClick={() => history.push('/signin/phone')}
        >
          <IconPhone />
          Sign in with phone
        </Button>

        <Button variant="block" className={styles.buttonApple} onClick={signIn}>
          <IconApple />
          Sign in with Apple
        </Button>

        <Button
          variant="block"
          className={styles.buttonEmail}
          onClick={() => history.push('/signin/email')}
        >
          <IconEmail />
          Sign in with email
        </Button>
      </div>
    </>
  );
}

export default Signin;
