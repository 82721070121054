import React from 'react';
import classnames from 'classnames';
import styles from './Box.module.scss';

function Title({ children }) {
  return <h2 className={styles.title}>{children}</h2>;
}

function Content({ children }) {
  return <div className={styles.content}>{children}</div>;
}

/**
 * @param {{
 * className?: string,
 * noPadding?: boolean
 * } & JSX.IntrinsicElements['div'] } props
 */
const Box = ({ noPadding = false, children, className, ...rest }) => {
  return (
    <div className={classnames(styles.box, className)} {...rest}>
      {noPadding ? children : <Content>{children}</Content>}
    </div>
  );
};

Box.Title = Title;
Box.Content = Content;

export default Box;
