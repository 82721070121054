import { useQuery } from '@apollo/react-hooks';
import React, { useMemo, useState } from 'react';
import { GET_POSTS } from './gql';
import FeedItem from 'components/FeedItem';
import Button from 'components/Button';
import Loading from 'components/Loading';
import PostComposer from './PostComposer/PostComposer';
import LayoutHeader from 'components/LayoutHeader';
import useTeam from 'hooks/useTeam';

function ListPosts() {
  const [fetchingMore, setFetchingMore] = useState(false);
  const team = useTeam();
  const { data, loading, fetchMore } = useQuery(GET_POSTS, { variables: { teamid: team._id } });
  const showLoadMore = useMemo(() => data && data?.posts?.pageInfo.hasNextPage, [data]);
  const loadMorePosts = () => {
    if (data.posts.pageInfo?.hasNextPage) {
      setFetchingMore(true);
      fetchMore({
        variables: {
          cursor: data.posts.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setFetchingMore(false);
          const newEdges = fetchMoreResult.posts.edges;
          const { pageInfo } = fetchMoreResult.posts;
          return newEdges.length
            ? {
                posts: {
                  ...previousResult.posts,
                  edges: previousResult.posts.edges.concat(newEdges),
                  pageInfo: {
                    ...previousResult.posts.pageInfo,
                    endCursor: pageInfo.endCursor,
                    hasNextPage: pageInfo.hasNextPage,
                  },
                },
              }
            : previousResult;
        },
      });
    }
  };
  return (
    <>
      <LayoutHeader helmetTitle="Posts" title="Posts" />
      <PostComposer />
      {loading && !data && <Loading />}
      {!loading &&
        data &&
        data.posts.edges.map(({ node }) => <FeedItem key={node._id} post={node} />)}
      {showLoadMore && (
        <Button variant="block" onClick={loadMorePosts} loading={fetchingMore}>
          Load more
        </Button>
      )}
    </>
  );
}

export default ListPosts;
