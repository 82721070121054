import React, { useCallback, useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from './Location.module.scss';
import LocationModal, { getDisplay } from './LocationModal';

function Location({ location, setLocation, className }) {
  const [showModal, setShowModal] = useState(false);
  const onSetLocation = useCallback(
    (location) => {
      setLocation(location);
      setShowModal(false);
    },
    [setLocation, setShowModal],
  );

  return (
    <div className={className}>
      <div className={styles.startdate}>
        <Input.Label>Location</Input.Label>
        <Button
          type="button"
          variant="reset"
          className={styles.locationSearchButton}
          onClick={() => setShowModal(true)}
        >
          {location.name ? (
            <div className={styles.selectedLocation}>
              <div className={styles.name}>{location.name}</div>
              <div className={styles.info}>{getDisplay(location)}</div>
            </div>
          ) : (
            <div className={styles.placeholder}>Add location</div>
          )}
        </Button>
      </div>
      {showModal && (
        <LocationModal setLocation={onSetLocation} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
}

export default Location;
