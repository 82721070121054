import Bugsnag from '@bugsnag/js';
import { getApolloClient } from '../apollo';
import { GQL_SELECTED_TEAM } from '../features/team/gql';
import { GQL_GET_ME } from '../features/user/gql';
import * as client from './analyticsClient/client';

const apollo = getApolloClient();

const trackPage = (location) => {
  const { selectedTeam } = apollo.cache.readQuery({
    query: GQL_SELECTED_TEAM,
  });
  let user;
  try {
    if (apollo.cache.extract().ROOT_QUERY?.me) {
      // Ignore if 'me' isnt in root query (yes, its a hack)
      user = apollo.cache.readQuery({ query: GQL_GET_ME });
    }
  } catch (e) {
    Bugsnag.notify(e);
  }
  client.trackPage(location, {
    user_id: user?.me?._id ?? window.localStorage.getItem('ajs_user_id'),
    team_id: selectedTeam,
  });
};

export { trackPage };
