import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import emojis from 'assets/emojis';
import ListItem from 'components/ListItem';
import ProfileImage from 'components/ProfileImage';
import Badge from '../../../components/Badge/Badge';
import Modal from 'components/Modal';
import Box from 'components/Box';
import PostReactions from './PostReactions';
import styles from './PostFooter.module.scss';
import { Link } from 'react-router-dom';

function PostFooter({ postid, seenBy, reactions }) {
  const [showSeenBy, setShowSeenBy] = useState(false);
  const [seen, notSeen] = useMemo(() => _.partition(seenBy, (s) => s.seen), [seenBy]);
  return (
    <>
      <ListItem.Title>Reactions &amp; Seen by</ListItem.Title>
      <div className={styles.footer}>
        <div className={styles.reactions}>
          <PostReactions postid={postid} reactions={reactions} />
        </div>
        <div className={styles.seenByBadge} onClick={() => setShowSeenBy(!showSeenBy)}>
          <Badge text={`Seen by (${seen.length}/${seen.length + notSeen.length})`} type="solid" />
        </div>
      </div>

      {showSeenBy && (
        <Modal onClose={() => setShowSeenBy(!showSeenBy)}>
          <Box>
            <div className={styles.grid}>
              <div>
                <ListItem.Title style={{ color: 'var(--color-positive)' }}>✓ Seen</ListItem.Title>
                {seen?.map((s, i) => (
                  <Link key={s.user._id} to={`/profile/${s.user._id}`}>
                    <ListItem
                      left={<ProfileImage user={s.user} />}
                      title={s.user.fullName}
                      subTitle={s.isSeenByParent && 'Seen by parent'}
                      right={
                        <div className={styles.seenByReactions}>
                          {s.reactions?.map((r) => (
                            <div key={r}>{emojis[r]}</div>
                          ))}
                        </div>
                      }
                      borderBottom={seen.length !== i + 1}
                    />
                  </Link>
                ))}
              </div>
              <div style={{ overflow: 'hidden' }}>
                <ListItem.Title style={{ color: 'var(--color-negative)' }}>
                  ✗ Not seen
                </ListItem.Title>
                {notSeen?.map((s, i) => (
                  <Link key={s.user._id} to={`/profile/${s.user._id}`}>
                    <ListItem
                      left={<ProfileImage user={s.user} />}
                      title={s.user.fullName}
                      borderBottom={notSeen.length !== i + 1}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default PostFooter;
