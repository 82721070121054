import ProPill from 'components/ProPill';
import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import TeamContext from 'contexts/Team';
import styles from './ChooseTeam.module.scss';

function ChooseTeamList({ loading, proTeams, numberOfNonProTeams }) {
  const history = useHistory();
  const { setSelectedTeam, selectedTeam } = useContext(TeamContext);
  const chooseTeam = (teamid) => (e) => {
    e.preventDefault();
    setSelectedTeam(teamid);
    history.replace('/');
  };
  return (
    <>
      <div className={styles.header}>
        <h4>Teams</h4>
        <ProPill />
      </div>
      <div className={styles.wrapperTeam}>
        {loading ? (
          <p>Loading teams</p>
        ) : (
          <>
            <ul>
              {proTeams.map((team) => (
                <li key={team._id} className={styles.listItem}>
                  <a href="/" onClick={chooseTeam(team._id)}>
                    <div
                      className={styles.teamColorPill}
                      style={{
                        borderColor: team.color,
                        ...(selectedTeam === team._id && { backgroundColor: team.color }),
                      }}
                    />
                    {team.name}
                  </a>
                </li>
              ))}
            </ul>
            {!!numberOfNonProTeams && (
              <>
                <div className={[styles.header, styles.headerNonProTeams].join(' ')}>
                  <Link to="/choose-team">
                    <h4>Non Pro Teams ({numberOfNonProTeams})</h4>
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ChooseTeamList;
