import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { getApolloClient } from './apollo';
import './assets/main.scss';
import { AuthenticationProvider } from './contexts/Authentication';
import { TeamProvider } from './contexts/Team';
import { UserProvider } from './contexts/User';
import Navigation from './Navigation';
import ErrorMessage from 'components/ErrorMessage';
import { trackPage } from './services/analytics';

const client = getApolloClient();

function App() {
  const location = useLocation();
  useEffect(() => {
    trackPage(location.pathname);
  }, [location.pathname]);

  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
        <UserProvider>
          <TeamProvider>
            <Navigation />
          </TeamProvider>
        </UserProvider>
      </AuthenticationProvider>
      <ErrorMessage />
    </ApolloProvider>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
