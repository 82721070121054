import React, { useEffect, useState, useContext, createContext, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { GQL_SET_AUTHTOKEN } from '../infra/authentication/gql';

import SigninStack from '../features/signin/SigninStack';
import { loginByCookie, logout } from '../network/auth';

const states = {
  SIGNING_IN: 'signing-in',
  SIGNED_IN: 'signed-in',
  SIGNED_OUT: 'signed-out',
};

const AuthenticationContext = createContext({
  authtoken: '',
  state: states.SIGNING_IN,
  states,
  signout: () => {},
  afterSigninRoute: '',
});

function AuthenticationProvider({ children }) {
  const ctx = useContext(AuthenticationContext);
  const [currentState, setCurrentState] = useState(states.SIGNING_IN);
  const [afterSigninRoute, setAfterLoginRoute] = useState('/');
  const [setStoreAuthToken] = useMutation(GQL_SET_AUTHTOKEN);
  const history = useHistory();
  const location = useLocation();

  const setAuthToken = useCallback(
    (token, validTo) => {
      setStoreAuthToken({ variables: { authToken: token, validTo } });
    },
    [setStoreAuthToken],
  );

  useEffect(() => {
    async function signIn() {
      try {
        const { data } = await loginByCookie();
        setAuthToken(data.authToken, parseInt(data.authTokenValidTo, 10));
        setCurrentState(states.SIGNED_IN);
      } catch (e) {
        console.log(e);
        setCurrentState(states.SIGNED_OUT);
        setAfterLoginRoute(location.pathname);
        history.replace('/');
      }
    }
    signIn();
  }, []); // eslint-disable-line
  const signout = useCallback(async () => {
    await logout();
    setAuthToken(ctx.authtoken);
    setCurrentState(states.SIGNED_OUT);
    try {
      window.localStorage.setItem('selectedTeam', '');
    } catch (e) {
      console.log(e);
    }
    history.replace('/');
  }, [ctx.authtoken, history, setAuthToken]);
  return (
    <AuthenticationContext.Provider
      value={{
        state: currentState,
        states,
        signout,
        afterSigninRoute,
      }}
    >
      {currentState === states.SIGNED_OUT ? (
        <SigninStack />
      ) : currentState === states.SIGNING_IN ? null : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationProvider };

export default AuthenticationContext;
