import React, { useState } from 'react';
import imageUrl from '../../services/images';
import styles from './Gallery.module.scss';
import { Lightbox } from './Lightbox';

const MAX_COLS = 2;

const Gallery = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  return (
    <>
      <div
        className={styles.gallery}
        style={{
          gridTemplateColumns: `repeat(${
            images.length <= MAX_COLS ? images.length : MAX_COLS
          }, minmax(0, 1fr))`,
        }}
      >
        {images.map((image, index) => (
          <div
            key={image._id}
            onClick={() => {
              setLightboxIsOpen(true);
              setImageIndex(index);
            }}
            className={styles.image}
            style={{ backgroundImage: `url(${imageUrl({ url: image.url, width: 512 })})` }}
          />
        ))}
      </div>

      {lightboxIsOpen && <Lightbox {...{ images, setLightboxIsOpen, imageIndex, setImageIndex }} />}
    </>
  );
};

export default Gallery;
