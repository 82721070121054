import gql from 'graphql-tag';

const Activities = {
  fragments: {
    activity: gql`
      fragment Activity on Activity {
        _id
        title
        startdate
        enddate
        meetdate
        location {
          name
          address
          country
          city
          longitude
          latitude
        }
        other
        tag {
          name
          color
        }
        opponent {
          _id
          name
          contactName
          phone
          email
        }
        timezone
        venue
        inviteType
        invitedGroups
        coachReminded
        isRecurring
        cancelled
        isPast
        maxAttendees
        rsvpDueDate
        members {
          _id
          attending
          answeredAt
          attendanceDetails {
            category
            note
          }
          seen
          invite
          user {
            _id
            fullName
            profileImage
          }
          role
        }
        me {
          _id
          attending
          attendanceDetails {
            category
            note
          }
          invite
          user {
            _id
            firstName
            fullName
            profileImage
          }
        }
        children {
          _id
          attending
          attendanceDetails {
            category
            note
          }
          invite
          user {
            _id
            firstName
            fullName
            profileImage
          }
        }
        status @client {
          deleted
        }
        state @client
      }
    `,
  },
};

const GET_ACTIVITIES_LIST = gql`
  query UpcomingActivitiesList($teamid: String!, $cursor: String, $past: Boolean) {
    activities(input: { teamid: $teamid, cursor: $cursor, past: $past }) {
      _id
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          _id
          title
          startdate
          meetdate
          tag {
            name
            color
          }
          opponent {
            _id
            name
            contactName
            phone
            email
          }
          location {
            name
            address
            country
            city
            longitude
            latitude
          }
          cancelled
          isPast
          maxAttendees
          rsvpDueDate
          members {
            attending
          }
          me {
            _id
            attending
            attendanceDetails {
              category
              note
            }
            invite
            user {
              _id
              firstName
              fullName
              profileImage
            }
          }
          children {
            _id
            attending
            attendanceDetails {
              category
              note
            }
            invite
            user {
              _id
              firstName
              fullName
              profileImage
            }
          }
          state @client
        }
      }
    }
  }
`;

const GET_ACTIVITY = gql`
  query GetActivity($teamid: String!, $activityid: String!) {
    activity(input: { teamid: $teamid, activityid: $activityid }) {
      node {
        ...Activity
      }
    }
  }
  ${Activities.fragments.activity}
`;

const SET_ATTENDING = gql`
  mutation SetAttending(
    $teamid: String!
    $activityid: String!
    $userid: String!
    $attending: Boolean
  ) {
    updateAttending(
      input: { teamid: $teamid, activityid: $activityid, userid: $userid, attending: $attending }
    ) {
      node {
        _id
        members {
          _id
          attending
          attendanceDetails {
            category
            note
          }
          answeredAt
        }
        children {
          _id
          attending
          attendanceDetails {
            category
            note
          }
        }
        me {
          _id
          attending
          attendanceDetails {
            category
            note
          }
        }
      }
    }
  }
`;

const SET_RSVP_DETAILS = gql`
  mutation SetRSVPDetails(
    $teamid: String!
    $activityid: String!
    $memberid: String!
    $category: String!
    $note: String
  ) {
    setRSVPDetails(
      input: {
        teamid: $teamid
        activityid: $activityid
        memberid: $memberid
        category: $category
        note: $note
      }
    ) {
      node {
        _id
        children {
          _id
          attendanceDetails {
            category
            note
          }
        }
        me {
          _id
          attendanceDetails {
            category
            note
          }
        }
      }
    }
  }
`;

const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($teamid: String!, $activityid: String!, $deleteFuture: Boolean) {
    deleteActivity(input: { teamid: $teamid, activityid: $activityid, deleteFuture: $deleteFuture })
  }
`;

const CANCEL_ACTIVITY = gql`
  mutation CancelActivity($teamid: String!, $activityid: String!, $cancelFuture: Boolean) {
    cancelActivity(input: { teamid: $teamid, activityid: $activityid, cancelFuture: $cancelFuture })
  }
`;

export {
  Activities,
  GET_ACTIVITIES_LIST,
  GET_ACTIVITY,
  SET_ATTENDING,
  SET_RSVP_DETAILS,
  DELETE_ACTIVITY,
  CANCEL_ACTIVITY,
};
