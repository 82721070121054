import * as React from 'react';

function AttendanceTeamIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 38 38" fill="none" {...props}>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.288 33.922l-6.465 3.13-2.942-6.554-6.964-1.758 1.96-6.91-4.205-5.825 5.942-4.034.523-7.164 7.146.73L19.288.384l5.005 5.151 7.146-.729.523 7.164 5.942 4.034L33.7 21.83l1.959 6.91-6.964 1.76-2.942 6.552-6.465-3.129z"
        fill="#2C3948"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.11 12.639L12.606 23.82l2.472 2.471L25.58 15.11l-2.47-2.471z"
        fill="#2C3948"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.53 27.341a3.151 3.151 0 11.001-6.301 3.151 3.151 0 010 6.302zM17.178 13.69a3.151 3.151 0 11-6.3-.001 3.151 3.151 0 016.3 0z"
        fill="#2C3948"
      />
    </svg>
  );
}

const MemoAttendanceTeamIcon = React.memo(AttendanceTeamIcon);
export default MemoAttendanceTeamIcon;
