import React from 'react';
import RSVPRow from '../RSVPRow';
import styles from './Activity.module.scss';

function ActivityRSVP({ activity: { _id, children, me } }) {
  if (children?.length === 0 && !me) {
    return null;
  }
  return (
    <div className={styles.rsvpRow}>
      {children?.length > 0 &&
        children.map((c) => <RSVPRow key={c._id} activityid={_id} member={c} />)}
      {!!me && <RSVPRow activityid={_id} member={me} />}
    </div>
  );
}

export default ActivityRSVP;
