import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import Button from 'components/Button';
import { GQL_ACTIVATE_TEAM } from '../gql';
import styles from './styles.module.scss';

export function ActivateTeam({ team, licenseKey }) {
  const [activate, { loading }] = useMutation(GQL_ACTIVATE_TEAM, {
    variables: {
      teamid: team._id,
      key: licenseKey,
    },
    refetchQueries: ['GetProSubscriptions'],
  });
  return (
    <div className={styles.ActivateTeam}>
      <div>
        <div style={{ backgroundColor: team.color }} />
        <span>{team.name}</span>
      </div>
      <Button onClick={activate} className={styles.btn} loading={loading}>
        Activate team
      </Button>
    </div>
  );
}
