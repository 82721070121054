import useTeam from 'hooks/useTeam';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import AttendanceIcon from './AttendanceIcon';
import styles from './MainNavigation.module.scss';
import PostsIcon from './PostsIcon';
import ScheduleIcon from './ScheduleIcon';
import UserListIcon from './UserListIcon';

const MainNavigation = ({ small = false }) => {
  const team = useTeam();
  const color = team?.color;
  if (!team._id) {
    return null;
  }
  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <NavLink to="/posts" className={styles.link} activeStyle={{ color }}>
          <PostsIcon />
          Posts
        </NavLink>
      </li>
      <li className={styles.item}>
        <NavLink to="/schedule" className={styles.link} activeStyle={{ color }}>
          <ScheduleIcon />
          Schedule
        </NavLink>
      </li>
      {(team.me?.isAdmin || team.me?.isCoach) && (
        <li className={styles.item}>
          <NavLink to="/attendance" className={styles.link} activeStyle={{ color }}>
            <AttendanceIcon />
            Attendance
          </NavLink>
        </li>
      )}
      {small && (
        <>
          <li className={styles.item}>
            <NavLink to="/team" className={styles.link} activeStyle={{ color }}>
              <UserListIcon />
              Team
            </NavLink>
          </li>
          <li>
            <ul className={styles.extra_items}>
              <li className={styles.extra_items__item}>
                <Link to="/choose-team">My teams</Link>
              </li>
              <li className={styles.extra_items__item}>
                <Link to="/signout">Sign out</Link>
              </li>
            </ul>
          </li>
        </>
      )}
    </ul>
  );
};

export default MainNavigation;
