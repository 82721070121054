import emojis from 'assets/emojis';

// Valid categories: 'sick', 'injured', 'vacation', 'arriving-late', 'leaving-early', 'need-a-ride', 'other'
const categories = {
  going: [
    { category: 'arriving-late', icon: emojis['arriving-late'] },
    { category: 'leaving-early', icon: emojis['leaving-early'] },
    { category: 'need-a-ride', icon: emojis.car },
    { category: 'other', icon: emojis.other },
  ],
  'not-going': [
    { category: 'sick', icon: emojis.sick },
    { category: 'injured', icon: emojis.injured },
    { category: 'vacation', icon: emojis.vacation },
    { category: 'other', icon: emojis.other },
  ],
};

export default categories;
