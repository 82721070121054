import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { loginByFacebook } from '../../network/auth';

function SigninWithCredential() {
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();
  const fbDetails = history.location.state.response?.authResponse;

  useEffect(() => {
    async function login() {
      try {
        await loginByFacebook({
          facebookToken: fbDetails.accessToken,
        });
        window.location.reload();
      } catch (e) {
        console.log(e);
        Bugsnag.notify(e);
        setErrorMsg(e.toString());
      }
    }
    login();
  }, [fbDetails]);

  return (
    <div>
      <h1>Sign in with Facebook</h1>
      {errorMsg.length > 0 && (
        <div className="mb-2 bg-red-500 p-2">
          <span className="text-white text-sm">
            {errorMsg.split('\n').map((row, ix) => (
              <div key={ix}>{row}</div>
            ))}
          </span>
        </div>
      )}
    </div>
  );
}

export default SigninWithCredential;
