import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Input from 'components/Input';

export default function Other({ other, setOther, className }) {
  return (
    <div className={className}>
      <Input.Label>Other</Input.Label>
      <TextareaAutosize
        onChange={(e) => setOther(e.target.value)}
        value={other}
        style={{ lineHeight: 1.5 }}
      />
    </div>
  );
}
