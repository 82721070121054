import { useCallback } from 'react';
import cryptojs from 'crypto-js';
import jsinject from 'services/jsinject';
import { loginByApple } from 'network/auth';
import { getApolloClient } from 'apollo';

const apollo = getApolloClient();

const state = cryptojs.enc.Base64.stringify(cryptojs.lib.WordArray.random(40));

async function signInApple() {
  await jsinject(
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  );
  window.AppleID.auth.init({
    clientId: 'io.heja.web',
    scope: 'name email',
    state,
    redirectURI: 'https://web.heja.io/signin/apple-return',
    usePopup: true,
  });
  return window.AppleID.auth.signIn();
}

function useAppleSignin() {
  const signIn = useCallback(async () => {
    try {
      const result = await signInApple();
      const { id_token, state: auth_state } = result.authorization;
      if (state !== auth_state) {
        throw new Error('`state` and `auth_state` is not equal');
      }
      await loginByApple({ identityToken: id_token });
      window.location.reload();
    } catch (e) {
      console.log(e);
      if (['popup_closed_by_user'].includes(e.error)) {
        return;
      }
      apollo.writeData({ data: { errorMessage: e.error || e.toString() } });
    }
  }, []);

  return { signIn };
}

export default useAppleSignin;
