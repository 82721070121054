import { useQuery } from '@apollo/react-hooks';
import { GQL_TEAM_MEMBER } from 'features/team/gql';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Badge from '../../components/Badge';
import ListItem from '../../components/ListItem';
import ProfileImage from '../../components/ProfileImage';
import styles from './Profile.module.scss';

const getConnections = ({ connections, singular, plural }) => (
  <>
    {connections.length > 1 ? plural : singular}:{' '}
    {connections.map((connection) => (
      <React.Fragment key={connection._id}>
        <Link to={`/profile/${connection._id}`}>{connection.fullName}</Link>
        {connections[connections.length - 1] === connection ? '' : ', '}
      </React.Fragment>
    ))}
  </>
);

const Profile = () => {
  const params = useParams();
  const { data, loading } = useQuery(GQL_TEAM_MEMBER, {
    variables: {
      userid: params.userid,
    },
  });

  const user = useMemo(() => !loading && data?.teamMember, [data, loading]);
  const phoneNumber = !!user?.phone ? parsePhoneNumberFromString(user.phone) : null;

  return (
    <>
      {user?.fullName && (
        <Helmet>
          <title>{user.fullName} | Heja | Sports team management and communication app</title>
        </Helmet>
      )}
      <article className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.center}>
            <ProfileImage {...{ user }} size={144} />
          </div>
          <h1 className={styles.title}>
            {user?.__typename === 'CoachMember' && <Badge text="Coach" type="solid" />}
            {user?.fullName}
          </h1>
          <div className={styles.connections}>
            {user?.children?.length > 0 &&
              getConnections({
                connections: user.children,
                singular: 'Child',
                plural: 'Children',
              })}
            {user?.parents?.length > 0 &&
              getConnections({
                connections: user.parents,
                singular: 'Parent',
                plural: 'Parents',
              })}
          </div>
        </header>
        <div>
          <ListItem
            title="Phone"
            subTitle={
              phoneNumber ? (
                <a href={phoneNumber.getURI()}>{phoneNumber.formatInternational()}</a>
              ) : (
                '―'
              )
            }
            reverse
          />

          <ListItem
            title="Email"
            subTitle={user?.email ? <a href={`mailto:${user?.email}`}>{user?.email}</a> : '―'}
            reverse
          />

          <ListItem title="Address" subTitle={user?.address || '―'} reverse />
        </div>
      </article>
    </>
  );
};

export default Profile;
