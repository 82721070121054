const aliases = {
  '+1': '👍',
  clap: '👏',
  muscle: '💪',
  joy: '😂',
  heart_eyes: '😍',
  sunglasses: '😎',
  disappointed_relieved: '😥',
  face_with_thermometer: '🤒',
  eyes: '👀',
  white_check_mark: '✅',
  trophy: '🏆',
  heart: '❤️',
  tada: '🎉',
  fire: '🔥',
  rocket: '🚀',
  hankey: '💩',
  pizza: '🍕',
  car: '🚗',
  sick: '🤒',
  injured: '🤕',
  vacation: '🏝',
  other: '✏️',
  'arriving-late': '⏱',
  'leaving-early': '🏃',
};

export default aliases;
