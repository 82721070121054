import React, { useEffect, useCallback, useReducer, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { addHours, addMinutes, subMinutes, differenceInMinutes, isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { CREATE_ACTIVITY, UPDATE_ACTIVITY } from './gql';
import { GET_ACTIVITY } from '../gql';
import Button from 'components/Button';
import LayoutHeader from 'components/LayoutHeader';
import Loading from 'components/Loading';
import Box from 'components/Box';
import AlertModal from 'components/AlertModal';
import useTeam from 'hooks/useTeam';
import styles from './ActivityComposer.module.scss';
import composerReducer, { initialState } from './composerReducer';
import Tags from './parts/Tags';
import Title from './parts/Title';
import Location from './parts/Location';
import Venue from './parts/Venue';
import Invites from './parts/Invites';
import DateTime from './parts/DateTime';
import Other from './parts/Other';
import Repeating from './parts/Repeating';
import Opponent from './parts/Opponent';

import { format } from 'date-fns-tz';
import RSVPSlots from './parts/RSVPSlots';
import RSVPDueDate from './parts/RSVPDueDate';

function calculateEnddate(startdate, enddate, duration) {
  if (duration.length > 0) {
    let enddate;
    if (duration.length > 0) {
      enddate = addHours(startdate, 1);
      if (duration === '1.5') {
        enddate = addMinutes(enddate, 30);
      }
      return enddate;
    }
  }
  return enddate;
}

function getNewMeetDate(oldStartDate, newStartDate, oldMeetTime) {
  if (oldMeetTime == null) {
    return null;
  }
  const minutes = differenceInMinutes(oldStartDate, oldMeetTime);
  return subMinutes(newStartDate, minutes);
}

function calculateMeetDate(startdate, meetdate) {
  return subMinutes(startdate, meetdate);
}

function generateTitle(opponent) {
  return `vs ${opponent?.name}`;
}

function ActivityComposer() {
  const params = useParams();
  const team = useTeam();
  const history = useHistory();
  const [
    {
      _id,
      title,
      location,
      startdate,
      enddate,
      meetdate,
      duration,
      tag,
      invitedGroups,
      invitedMembers,
      other,
      recurrence,
      opponent: currentOpponent,
      venue,
      maxAttendees,
      rsvpDueDate,
    },
    dispatch,
  ] = useReducer(composerReducer, initialState);

  const isEditing = useMemo(() => params.activityid?.length === 24, [params]);
  const [confirmUpdate, setConfirmUpdate] = useState(false);

  // "setters"
  const setField = useCallback((field, value) => dispatch({ type: 'set', field, value }), [
    dispatch,
  ]);
  const setTitle = useCallback((title) => setField('title', title), [setField]);
  const setOther = useCallback((other) => setField('other', other), [setField]);
  const setOpponent = useCallback(
    (opponent) => {
      const currentTitleSeemsGenerated = generateTitle(currentOpponent) === title;
      if (currentTitleSeemsGenerated || !title?.trim()) {
        setField('title', generateTitle(opponent));
      }

      setField('opponent', opponent);
    },
    [currentOpponent, setField, title],
  );
  const setLocation = useCallback((location) => setField('location', location), [setField]);
  const setVenue = useCallback((venue) => setField('venue', venue), [setField]);
  const setStartdate = useCallback(
    (date) => {
      setField('startdate', date);
      setField('enddate', calculateEnddate(date, enddate, duration));
      setField('meetdate', getNewMeetDate(startdate, date, meetdate));
    },
    [duration, enddate, meetdate, setField, startdate],
  );
  const setEnddate = useCallback((date) => setField('enddate', date), [setField]);
  const setMeetdate = useCallback(
    (minutes) => setField('meetdate', calculateMeetDate(startdate, minutes)),
    [startdate, setField],
  );
  const setDuration = useCallback(
    (d) => {
      if (duration === d) {
        setField('duration', '');
      } else {
        setField('duration', d);
        setField('enddate', calculateEnddate(startdate, enddate, d));
      }
    },
    [duration, enddate, setField, startdate],
  );
  const setTag = useCallback((tag) => setField('tag', tag), [setField]);
  const setInvitedGroups = useCallback(
    (invitedGroups) => setField('invitedGroups', invitedGroups),
    [setField],
  );
  const setInvitedMembers = useCallback(
    (invitedMembers) => setField('invitedMembers', invitedMembers),
    [setField],
  );
  const setRepeating = useCallback((repeating) => setField('recurrence.isRecurring', repeating), [
    setField,
  ]);
  const setRepeatingEnddate = useCallback((date) => setField('recurrence.enddate', date), [
    setField,
  ]);

  const toggleInvitedGroup = useCallback(
    (group) => () => {
      setInvitedMembers([]);
      setInvitedGroups(
        invitedGroups.includes(group)
          ? invitedGroups.filter((g) => g !== group)
          : [...invitedGroups, group],
      );
    },
    [invitedGroups, setInvitedGroups, setInvitedMembers],
  );

  const addInvitedMember = useCallback(
    (userid) => {
      setInvitedGroups([]);
      setInvitedMembers(_.concat(invitedMembers, userid));
    },
    [invitedMembers, setInvitedGroups, setInvitedMembers],
  );

  const removeInvitedMember = useCallback(
    (userid) => {
      setInvitedGroups([]);
      setInvitedMembers(_.without(invitedMembers, userid));
    },
    [invitedMembers, setInvitedGroups, setInvitedMembers],
  );

  const setMaxAttendees = useCallback(
    (numAttendees) => {
      const parsedNumAttendees = parseInt(numAttendees, 10);
      const maxAttendees = isNaN(parsedNumAttendees) ? null : Math.max(parsedNumAttendees, 0);

      setField('maxAttendees', maxAttendees);
    },
    [setField],
  );

  const setRsvpDueDate = useCallback(
    (date) => {
      if (!date) {
        setField('rsvpDueDate', null);
        return;
      }

      const parsedDueDate = new Date(date);

      if (isAfter(parsedDueDate, startdate)) {
        return;
      }

      setField('rsvpDueDate', parsedDueDate);
    },
    [setField, startdate],
  );

  // graphqls
  const [mutationCreateActivity, { loading: createLoading }] = useMutation(CREATE_ACTIVITY);
  const [mutationUpdateActivity, { loading: updateLoading }] = useMutation(UPDATE_ACTIVITY);
  const { data: activity, loading } = useQuery(GET_ACTIVITY, {
    variables: { activityid: params.activityid },
    skip: !isEditing,
    onCompleted: (data) => dispatch({ type: 'reset', collection: data?.activity?.node }),
  });

  const onCreate = async () => {
    const variables = {
      title,
      other,
      location: _.omit(location, ['__typename']),
      timezone: format(new Date(), 'z'),
      startdate,
      enddate,
      meetdate: meetdate,
      tag: _.pick(tag, ['name', 'color']),
      venue: tag?.name === 'Game' ? venue : null,
      invitedGroups,
      opponent: currentOpponent,
      invites: invitedMembers,
      ...(!isEditing && recurrence.isRecurring && { recurrence }),
      maxAttendees,
      rsvpDueDate,
    };
    await mutationCreateActivity({
      variables,
      refetchQueries: ['UpcomingActivitiesList'],
      awaitRefetchQueries: true,
    });
    history.replace('/schedule');
  };

  const onUpdate = (updateFuture) => async () => {
    const variables = {
      activityid: _id,
      title,
      other,
      location: _.omit(location, ['__typename']),
      timezone: format(new Date(), 'z'),
      startdate,
      enddate,
      meetdate: meetdate,
      tag: _.pick(tag, ['name', 'color']),
      venue: tag?.name === 'Game' ? venue : null,
      invitedGroups,
      invites: invitedMembers,
      opponent: _.omit(currentOpponent, '__typename'),
      updateFuture,
      maxAttendees,
      rsvpDueDate,
    };
    await mutationUpdateActivity({ variables });
    history.push(`/schedule/${_id}`);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isEditing && activity?.activity?.node.isRecurring) {
      setConfirmUpdate(true);
    } else if (isEditing) {
      onUpdate()();
    } else {
      onCreate();
    }
  };

  useEffect(() => {
    if (!isEditing && team) {
      setTag(team.activityTags[0]);
    }
  }, [isEditing, setTag, team]);

  const t = params.activityid ? 'Edit activity' : 'New activity';
  return (
    <>
      <LayoutHeader helmetTitle={t} title={t} />
      {loading && !activity ? (
        <Loading />
      ) : (
        <form className={styles.form}>
          <Tags {...{ activityTags: team?.activityTags, setTag, tag }} className={styles.space} />
          <Box>
            {tag?.name === 'Game' ? (
              <Opponent
                opponent={currentOpponent}
                setOpponent={setOpponent}
                className={styles.space}
              />
            ) : null}
            <Title {...{ title, setTitle }} className={styles.space} />
            <Location {...{ location, setLocation }} className={styles.space} />
            {tag?.name === 'Game' ? (
              <Venue className={styles.space} venue={venue} setVenue={setVenue} />
            ) : null}
            <Other {...{ other, setOther }} />
          </Box>

          <Box>
            <DateTime
              {...{
                startdate,
                setStartdate,
                enddate,
                setEnddate,
                duration,
                setDuration,
                meetdate,
                setMeetdate,
              }}
            />
          </Box>

          <Box>
            <Invites
              {...{
                invitedGroups,
                toggleInvitedGroup,
                invitedMembers,
                addInvitedMember,
                removeInvitedMember,
              }}
            />
          </Box>

          <Box>
            <RSVPSlots maxAttendees={maxAttendees} setMaxAttendees={setMaxAttendees} />
          </Box>

          <Box>
            <RSVPDueDate
              rsvpDueDate={rsvpDueDate}
              setRsvpDueDate={setRsvpDueDate}
              startDate={startdate}
            />
          </Box>

          {!isEditing && (
            <Box>
              <Repeating
                {...{
                  repeating: recurrence.isRecurring,
                  startdate,
                  enddate: recurrence.enddate,
                  setRepeating,
                  setRepeatingEnddate,
                }}
              />
            </Box>
          )}

          <Button
            type="submit"
            onClick={onSubmit}
            className={styles.space}
            loading={createLoading || updateLoading}
          >
            {isEditing ? 'Save' : 'Schedule'}
          </Button>

          {confirmUpdate && (
            <AlertModal
              title="Update repeating activity"
              body="This is a repeating activity."
              onClose={() => setConfirmUpdate(false)}
              buttons={[
                { text: 'Cancel', variant: 'inverted', onClick: () => setConfirmUpdate(false) },
                { text: 'Update only this', onClick: onUpdate(false) },
                { text: 'Update all future', onClick: onUpdate(true) },
              ]}
            />
          )}
        </form>
      )}
    </>
  );
}

export default ActivityComposer;
