import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Schedule from 'features/activities/ActivitiesScreen';
import Attendance from 'features/attendance/AttendanceScreen';
import Feed from 'features/posts/Feed';
import Signout from 'features/signin/SignoutScreen';
import ChooseTeam from 'features/team/ChooseTeam/ChooseTeamScreen';
import Layout from './components/Layout';
import AuthenticationContext from './contexts/Authentication';
import Profile from './features/profile/Profile';
import Team from './features/team/Team';
import NonProTeams from './features/license-activation/NonProTeams';
import ActivateFastspringSubs from './features/license-activation/fastspring/ActivateFastspringSubs';
import ActivateFastspringViaSubscription from 'features/license-activation/fastspring/ActivateFastspringViaSubscription';

function Navigation() {
  const { state, states } = useContext(AuthenticationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (state === states.SIGNING_IN) {
    return null;
  }

  return (
    <Layout>
      <Switch>
        <Route path="/schedule" component={Schedule} />
        <Route
          path="/activate-subscription/:subscriptionid"
          component={ActivateFastspringViaSubscription}
        />
        <Route path="/activate/:orderid" component={ActivateFastspringSubs} />
        <Route path="/activate" component={NonProTeams} />
        <Route path="/choose-team" component={ChooseTeam} />
        <Route path="/team" component={Team} />
        <Route path="/posts" component={Feed} />
        <Route path="/attendance/:type?" component={Attendance} />
        <Route path="/profile/:userid?" component={Profile} />
        <Route path="/signout" component={Signout} />
        <Route path="/" component={Feed} exact />
      </Switch>
    </Layout>
  );
}

export default Navigation;
