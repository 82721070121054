import React from 'react';
import classnames from 'classnames';
import styles from './AttendanceBar.module.scss';

function AttendanceBar({ foreground, loading, completion }) {
  return (
    <div
      className={classnames({
        [styles.barBg]: true,
        [styles.loading]: loading,
      })}
    >
      <div
        className={styles.bar}
        style={{ width: `${completion}%`, ...(!loading && { backgroundColor: foreground }) }}
      />
    </div>
  );
}

export default AttendanceBar;
