import React, { useContext, useReducer, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { CREATE_POST, GET_FILES_UPLOAD_INFO } from './gql';

import Box from '../../../components/Box';
import ProfileImage from '../../../components/ProfileImage';
import Button from '../../../components/Button';
import UserContext from '../../../contexts/User';
import { initialState, composerReducer } from './composerReducer';
import ComposerText from './ComposerText';
import { envelopeFile } from './lib';
import AttachmentPreview from './AttachmentPreview';
import uploaders from '../../../network/uploader';
import styles from './PostComposer.module.scss';

const defaultComposerText = initialState.text;

const attachmentFieldMapper = {
  ImageUploadInfo: (attachment) => ({
    type: 'image',
    url: attachment.uploadData.secure_url,
    width: attachment.uploadData.width,
    height: attachment.uploadData.height,
  }),
  DocumentUploadInfo: (attachment) => ({
    type: 'document',
    url: attachment.uploadInfo.filename,
    metaSize: attachment.file.size,
    metaContentType: attachment.file.type,
    metaTitle: attachment.file.name,
    metaDocumentId: attachment.uploadInfo.documentId,
  }),
};

/**
 * File uploader
 * Upload file (image/document) steps:
 * [X] 1. User inputs files
 * [X] 2. Get file data and assign a unique identifier for file
 * [X] 3. Add attachments to state
 * [X] 4. Request from GET_FILES_UPLOAD_INFO to classify the file and get upload info
 * [X] 5. Determine which upload handler to use based on __typename
 * [X] 6. Upload with handler that corresponds to the __typename and adds progress to attachment in state
 * [X] 7. We get data in return that should populate the attachment for upload
 * [X] 8. Conform attachment data to type input object
 * [X] 9. Send attachment with CreatePost mutation
 */

// Utilizes the __typename in gql result
const uploadHandlers = {
  DocumentUploadInfo: uploaders.documents,
  ImageUploadInfo: uploaders.images,
};

function uploadInfoHandler({ attachments, setAttachmentField, removeAttachment }) {
  // 5.
  return async (data) =>
    data.uploadInfo.files.map(async (uploadInfo) => {
      const { identifier, __typename } = uploadInfo;
      const attachment = attachments.find((a) => a.id === identifier);
      if (!attachment) {
        console.log('Attachment not found in client:', attachment, {
          identifier,
        });
        return;
      }
      if (__typename === 'UnsupportedUploadInfo') {
        // TODO: Add some nice popup that tells the user its unsupported
        console.log('Unsupported:', attachment);
        removeAttachment(attachment);
        return;
      }
      setAttachmentField('typename', __typename, attachment);
      setAttachmentField('uploadInfo', uploadInfo, attachment);
      // 6.
      const result = await uploadHandlers[__typename]({
        file: attachment.file,
        uploadInfo,
        onProgress: (progress) => setAttachmentField('uploadProgress', progress, attachment),
      });
      // 7.
      setAttachmentField('uploadData', result.data, attachment);
      setAttachmentField('loading', false, attachment);
    });
}

function onFileDrop({ addAttachment, filesUploadInfo }) {
  return async (files) => {
    // 1 & 2 & 3
    const envelopedFiles = await Promise.all(
      files.map(async (file) => {
        const envelopedFile = await envelopeFile({ file });
        addAttachment(envelopedFile);
        return envelopedFile;
      }),
    );
    // 4
    filesUploadInfo({
      variables: {
        files: envelopedFiles.map(({ id, file }) => ({
          identifier: id,
          filename: file.name,
          type: file.type,
        })),
      },
    });
  };
}

function PostComposer() {
  const { user } = useContext(UserContext);
  const [{ text, attachments }, dispatch] = useReducer(composerReducer, {
    ...initialState,
    text: defaultComposerText,
  });

  const addAttachment = useCallback((attachment) => dispatch({ type: 'add', attachment }), [
    dispatch,
  ]);
  const setAttachmentField = useCallback(
    (field, value, attachment) =>
      dispatch({
        type: 'set_attachment_field',
        attachment,
        field,
        value,
      }),
    [dispatch],
  );
  const removeAttachment = useCallback((attachment) => dispatch({ type: 'remove', attachment }), [
    dispatch,
  ]);
  const setText = useCallback((input) => dispatch({ type: 'set_text', input }), [dispatch]);

  const [filesUploadInfo] = useLazyQuery(GET_FILES_UPLOAD_INFO, {
    onCompleted: uploadInfoHandler({
      attachments,
      setAttachmentField,
      removeAttachment,
    }),
  });

  const composeDropzone = useDropzone({
    onDrop: onFileDrop({ addAttachment, filesUploadInfo }),
  });
  const canPost = useMemo(
    () =>
      text.length > 0 ||
      (attachments?.length > 0 && !attachments.some((attachment) => attachment.loading)),
    [text, attachments],
  );

  const [createPost, { loading }] = useMutation(CREATE_POST);
  const onSubmit = async (e) => {
    e.preventDefault();

    return createPost({
      variables: {
        text,
        attachments: attachments.map((a) => attachmentFieldMapper[a.typename](a)),
      },
      refetchQueries: ['GetPosts'],
      awaitRefetchQueries: true,
      update: () => {
        dispatch({ type: 'reset_composer' });
      },
    });
  };

  const [images, documents] = attachments?.reduce(
    (acc, curr) => {
      acc[curr.typename === 'ImageUploadInfo' ? 0 : 1].push(curr);
      return acc;
    },
    [[], []],
  ) ?? [[], []];

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <div className={styles.composer}>
          <ProfileImage user={user} />
          <ComposerText
            setText={setText}
            text={text}
            onFocus={() => text === defaultComposerText && setText('')}
            onBlur={() => text === '' && setText(defaultComposerText)}
          />
        </div>

        {images?.length > 0 && (
          <div className={styles.imageAttachmentPreviews}>
            {images?.map((attachment) => (
              <AttachmentPreview
                key={attachment.id}
                attachment={attachment}
                remove={() => removeAttachment(attachment)}
              />
            ))}
          </div>
        )}
        {documents?.length > 0 && (
          <div className={styles.documentAttachmentPreviews}>
            {documents?.map((attachment) => (
              <AttachmentPreview
                key={attachment.id}
                attachment={attachment}
                remove={() => removeAttachment(attachment)}
              />
            ))}
          </div>
        )}
        <div className={styles.buttonRow}>
          <div className={styles.buttonRowAdd} {...composeDropzone.getRootProps()}>
            <input {...composeDropzone.getInputProps({ multiple: true })} />{' '}
            <Button variant="round" className={styles.imageButton}>
              {composeDropzone.isDragActive ? 'Drop image here' : '+ Image'}
            </Button>
            <Button variant="round" className={styles.documentButton}>
              {composeDropzone.isDragActive ? 'Drop document here' : '+ Document'}
            </Button>
          </div>
          <Button type="submit" disabled={!canPost} {...{ loading }} variant="round">
            Post
          </Button>
        </div>
      </Box>
    </form>
  );
}

export default PostComposer;
