import * as React from 'react';

function AddReactionIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M7.1 3.4C-1.6 8.2 2.1 22 12 22c5.1 0 10-4.9 10-9.9C22 4.6 13.7-.3 7.1 3.4zm10.4 3.1c3.3 3.2 3.3 7.8 0 11C12.6 22.5 4 19 4 12c0-4.1 3.9-8 8-8 1.9 0 4 .9 5.5 2.5z" />
      <path d="M7 9.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5S9.3 8 8.5 8 7 8.7 7 9.5zM14 9.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5S16.3 8 15.5 8 14 8.7 14 9.5zM8 15.5c1.6 1.9 6.4 1.9 8 0 1.1-1.3.5-1.5-4-1.5s-5.1.2-4 1.5z" />
    </svg>
  );
}

const MemoAddReactionIcon = React.memo(AddReactionIcon);
export default MemoAddReactionIcon;
