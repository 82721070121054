import React from 'react';
import IconCheckbox from './IconCheckbox';
import IconCheckboxChecked from './IconCheckboxChecked';
import Button from 'components/Button';
import styles from './CheckboxButton.module.scss';

function CheckboxButton({ checked, className, children, ...rest }) {
  return (
    <Button
      type="button"
      variant="reset"
      className={[styles.button, className].join(' ')}
      {...rest}
    >
      {checked ? <IconCheckboxChecked /> : <IconCheckbox />}
      {children}
    </Button>
  );
}

export default CheckboxButton;
