import HejaLogo from 'components/HejaLogo';
import useTeam from 'hooks/useTeam';
import React from 'react';
import { Link } from 'react-router-dom';

export function Logo({ navable, className }) {
  const team = useTeam();
  return navable ? (
    <Link className={className} to="/posts">
      <HejaLogo style={{ fill: team.color || 'var(--color-green)' }} />
      <h1 className="screen-reader-text">Heja</h1>
    </Link>
  ) : (
    <div className={className} to="/posts">
      <HejaLogo style={{ fill: team.color || 'var(--color-green)' }} />
      <h1 className="screen-reader-text">Heja</h1>
    </div>
  );
}
