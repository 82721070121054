import Badge from 'components/Badge/Badge';
import ListItem from 'components/ListItem';
import ProfileImage from 'components/ProfileImage';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TeamMemberList.module.scss';

function TeamMemberConnection({ connections = [], singular, plural }) {
  if (!connections || connections.length === 0) {
    return null;
  }
  return `${connections.length > 1 ? plural : singular}: ${connections
    .map((c) => c.fullName)
    .join(', ')}`;
}

function TeamMember({ member, borderBottom = false }) {
  return (
    <>
      <Link to={`/profile/${member.userid}`}>
        <ListItem
          style={{ cursor: 'pointer' }}
          left={<ProfileImage user={member} />}
          title={member.fullName}
          subTitle={
            <>
              <TeamMemberConnection
                connections={member.children}
                singular="Child"
                plural="Children"
              />
              <TeamMemberConnection
                connections={member.parents}
                singular="Parent"
                plural="Parents"
              />
            </>
          }
          right={
            <div className={styles.right}>
              {member.tags?.includes('admin') && <Badge text="Team admin" type="line" />}
            </div>
          }
          borderBottom={borderBottom}
        />
      </Link>
    </>
  );
}

export default TeamMember;
