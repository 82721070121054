import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import emojis from 'assets/emojis';
import useTeam from 'hooks/useTeam';
import useUser from 'hooks/useUser';
import styles from './Reaction.module.scss';

function Reaction({ reaction, reactions, toggleReaction }) {
  const { color } = useTeam();
  const user = useUser();
  const userHasReacted = useMemo(() => reactions.some((r) => r.user._id === user._id), [
    reactions,
    user,
  ]);
  const onClick = useCallback(() => {
    toggleReaction(reaction);
  }, [reaction, toggleReaction]);

  return (
    <button className={styles.reaction} onClick={onClick}>
      {emojis[reaction]}
      <span
        className={classnames(styles.count, { [styles.hasReacted]: userHasReacted })}
        style={{ backgroundColor: userHasReacted ? color : 'white' }}
      >
        {reactions.length}
      </span>
    </button>
  );
}

export default Reaction;
