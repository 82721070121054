import React from 'react';
import './Alert.scss';

// @param type: ('info'|'positive'|'negative')
const Alert = ({ children, type = 'info' }) => (
  <div className={`alert alert--${type}`} role="alert">
    {children}
  </div>
);

export default Alert;
