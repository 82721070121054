import React from 'react';
import styles from './LayoutHeader.module.scss';
import { Helmet } from 'react-helmet';

function LayoutHeader({ left, title, right, helmetTitle }) {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{helmetTitle} | Heja | Sports team management and communication app</title>
      </Helmet>
      {React.isValidElement(left) && <div className={styles.left}>{left}</div>}
      {React.isValidElement(title) ? title : <h2>{title}</h2>}
      {React.isValidElement(right) && <div className={styles.right}>{right}</div>}
    </div>
  );
}

export default LayoutHeader;
