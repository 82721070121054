import React, { useState, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Button from 'components/Button';
import { GET_TEAM_BY_CODE } from './gql';
import styles from './styles.module.scss';
import { formatCode, TeamCodeRegEx } from './lib';
import { ActivateTeam } from './ActivateTeam';

function FindTeam({ subscription }) {
  const [teamCode, setTeamCode] = useState('');
  const [canSearch, setCanSearch] = useState(false);
  const [getTeam, { data: teamData, loading }] = useLazyQuery(GET_TEAM_BY_CODE);

  const onChange = useCallback(
    (e) => {
      const code = formatCode(e.target.value);
      setTeamCode(code);
      setCanSearch(TeamCodeRegEx.test(code.toUpperCase()));
    },
    [setTeamCode],
  );

  const findTeam = useCallback(() => {
    getTeam({
      variables: {
        code: teamCode.replace('-', ''),
      },
    });
  }, [teamCode, getTeam]);

  useEffect(() => {
    if (canSearch) {
      findTeam();
    }
  }, [canSearch, findTeam]);

  if (teamData?.getTeamByCode) {
    return <ActivateTeam team={teamData.getTeamByCode} licenseKey={subscription.licenseKey} />;
  }

  return (
    <div className={styles.FindTeam}>
      <input
        value={teamCode}
        onChange={onChange}
        placeholder="Enter team code"
        disabled={loading}
      />
      <Button
        className={styles.btn}
        onClick={findTeam}
        disabled={!canSearch || loading}
        loading={loading}
      >
        Activate team
      </Button>
    </div>
  );
}

export { FindTeam };
