import gql from 'graphql-tag';

const GET_ATTENDANCE = gql`
  query GetAttendance($teamid: String!, $startdate: Date!, $enddate: Date) {
    attendance(input: { teamid: $teamid, startdate: $startdate, enddate: $enddate }) {
      overall {
        practice {
          invited
          attended
        }
        game {
          invited
          attended
        }
        competition {
          invited
          attended
        }
        combined {
          invited
          attended
        }
      }
      members {
        user {
          _id
          fullName
          profileImage
        }
        stats {
          game {
            invited
            attended
          }
          competition {
            invited
            attended
          }
          practice {
            invited
            attended
          }
          combined {
            invited
            attended
          }
        }
      }
    }
  }
`;

export { GET_ATTENDANCE };
