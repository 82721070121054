import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME_TEAMS } from 'features/user/gql';
import _ from 'lodash';
import ChooseTeamList from './ChooseTeamList';
import { Link } from 'react-router-dom';

function ChooseTeam() {
  const { data, loading } = useQuery(GET_ME_TEAMS);
  const [proTeams, nonProTeams] = _.partition(_.get(data, 'me.teams', []), (t) => t.hasAccess);
  const numberOfNonProTeams = proTeams.length > 5 ? null : nonProTeams.length;

  return (
    <>
      <ChooseTeamList {...{ proTeams: proTeams.slice(0, 5), numberOfNonProTeams, loading }} />
      {proTeams.length > 5 && (
        <div style={{ marginLeft: 10, fontSize: '0.8rem', color: '#999' }}>
          <Link to="/choose-team">
            <p>Show all teams</p>
          </Link>
        </div>
      )}
    </>
  );
}

export default ChooseTeam;
