import * as React from 'react';

function IconApple(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 29" fill="none" {...props}>
      <path
        d="M17.533 7.38c-2.47 0-3.514 1.18-5.235 1.18-1.764 0-3.109-1.17-5.25-1.17-2.095 0-4.33 1.279-5.749 3.458-1.992 3.073-1.654 8.861 1.573 13.792 1.154 1.765 2.696 3.745 4.718 3.766h.037c1.757 0 2.279-1.15 4.697-1.164h.037c2.382 0 2.86 1.157 4.61 1.157h.036c2.022-.02 3.646-2.214 4.8-3.973.832-1.264 1.14-1.899 1.777-3.33-4.668-1.771-5.417-8.39-.8-10.926-1.41-1.765-3.39-2.787-5.257-2.787l.006-.002z"
        fill="#fff"
      />
      <path
        d="M16.989.956c-1.47.1-3.186 1.036-4.19 2.259-.913 1.107-1.662 2.75-1.368 4.344h.117c1.566 0 3.169-.943 4.105-2.151.902-1.15 1.586-2.78 1.336-4.452z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoIconApple = React.memo(IconApple);
export default MemoIconApple;
