import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListPosts from './ListPosts';
import Post from './Post';

function Feed() {
  return (
    <Switch>
      <Route path="/posts/:postid" component={Post} />
      <Route exact path="/" render={() => <Redirect to="/posts" />} />
      <Route path="/posts" component={ListPosts} />
    </Switch>
  );
}

export default Feed;
