import React from 'react';

import AlertModal from '../AlertModal';

function ConfirmModal({ title, body, confirmText, onConfirm, onClose }) {
  return (
    <AlertModal
      onClose={onClose}
      title={title}
      body={body}
      buttons={[
        { text: 'Cancel', variant: 'inverted', onClick: onClose },
        { text: 'Confirm', onClick: onConfirm },
      ]}
    />
  );
}

export default ConfirmModal;
