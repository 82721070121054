import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME_TEAMS } from 'features/user/gql';
import _ from 'lodash';
import ChooseTeamList from './ChooseTeamList';
import LayoutHeader from 'components/LayoutHeader';
import styles from './ChooseTeam.module.scss';

function ChooseTeam() {
  const { data, loading } = useQuery(GET_ME_TEAMS);
  const [proTeams, nonProTeams] = _.partition(_.get(data, 'me.teams', []), (t) => t.hasAccess);
  return (
    <>
      <LayoutHeader helmetTitle="Choose team" title="Choose team" />
      {!loading && proTeams.length > 0 && (
        <ChooseTeamList {...{ proTeams, loading, numberOfNonProTeams: nonProTeams?.length || 0 }} />
      )}
      {proTeams.length === 0 && (
        <p className={styles.emptyTeamList}>
          Non of your teams are on the Team Pro or Team Pro Max plan. Start a 14 day free trail to
          enable the desktop version for your team.
        </p>
      )}
      <div className={styles.upgradeWrapper}>
        <a href="https://heja.io/pro" className={styles.upgradeButton}>
          Add or manage teams
        </a>
        {proTeams.length > 0 && (
          <p className={styles.upgradeText}>
            Only teams on the Team Pro or Team Pro Max plan have access to the desktop version and
            show here.
          </p>
        )}
      </div>
    </>
  );
}

export default ChooseTeam;
