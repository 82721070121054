import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import AppButton from '../AppButton/AppButton';
import styles from './ToggleMenu.module.scss';

function remToPx(value) {
  return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const ToggleMenu = ({ toggleText = '', toggleIcon = 'caret', menuItems = [] }) => {
  const wrapperRef = useRef();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, remToPx(0.5)],
        },
      },
    ],
  });
  const [isOpen, setIsOpen] = useState(false);

  const onToggleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useOnClickOutside(wrapperRef, () => setIsOpen(false), popperElement?.current);

  return (
    <div ref={wrapperRef}>
      <AppButton
        type={toggleIcon}
        ref={setReferenceElement}
        onClick={onToggleClick}
        text={toggleText}
      />

      {isOpen && (
        <ul
          ref={setPopperElement}
          className={styles.menu}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {menuItems.map(({ content, destructive, ...rest }, idx) => (
            <li
              key={idx}
              className={classNames({
                [styles.menuItem]: true,
                [styles.menuItemDestructive]: destructive,
              })}
              onClick={onToggleClick}
              {...rest}
            >
              {content}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ToggleMenu;
