import React from 'react';

import Modal from '../Modal';
import Box from '../Box';
import Button from '../Button';

import styles from './AlertModal.module.scss';

function AlertModal({ title, body, onClose, buttons }) {
  return (
    <Modal onClose={onClose}>
      <Box className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p>{body}</p>
        <div className={styles.buttons}>
          {buttons?.map(({ onClick, variant, text }, ix) => (
            <Button key={ix} onClick={onClick} variant={variant}>
              {text}
            </Button>
          )) ?? <Button onClick={onClose}>OK</Button>}
        </div>
      </Box>
    </Modal>
  );
}

export default AlertModal;
