import * as React from 'react';

function ClockIcon(props) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.698 8.001A7.913 7.913 0 018.613.087 7.913 7.913 0 0116.527 8a7.913 7.913 0 01-7.914 7.915A7.913 7.913 0 01.698 8zM8.613 9.22c.293 0 .533-.24.533-.532V3.13a.534.534 0 00-.533-.532.534.534 0 00-.533.532v5.023H4.96a.534.534 0 00-.533.533c0 .293.24.532.533.532h3.653z"
        fill="#2A3A4A"
      />
    </svg>
  );
}

export default ClockIcon;
