import React, { useCallback, useMemo } from 'react';
import styles from './Switch.module.scss';

function Switch({ checked, onChange, pillColor }) {
  const id = useMemo(() => `cb-${Math.round(Math.random() * 10000)}`, []);
  const handleOnchange = useCallback(
    (e) => {
      onChange && onChange(e.target.checked);
    },
    [onChange],
  );
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.input}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleOnchange}
      />
      <label className={styles.label} htmlFor={id}>
        <span className={styles.button} style={pillColor && { backgroundColor: pillColor }} />
      </label>
    </div>
  );
}

export default Switch;
