import React, { useEffect } from 'react';
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button';
import styles from './SigninScreen.module.scss';
import useSignIn from './useSignIn';

function SigninWithCredential({ credentialType }) {
  const { state, setCredentialType, setCredential, formSubmit, setCode } = useSignIn();

  useEffect(() => {
    setCredentialType(credentialType);
  }, [setCredentialType, credentialType]);

  return (
    <div>
      <h1 className={styles.title}>
        Sign in with <span>{state.credentialType}</span>
      </h1>

      <form onSubmit={formSubmit}>
        {state.verificationRef?.length > 0 ? (
          <>
            <p>Enter 4-digit code</p>
            <input
              ref={(el) => el?.focus?.()}
              type="number"
              value={state.code}
              onChange={(e) => setCode(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
          </>
        ) : (
          <>
            <p>
              {state.credentialType === 'phone' ? (
                <span>
                  Enter your phone number including country code (e.g. +1) then we’ll send you a
                  4-digit code to verify your phone number.
                </span>
              ) : (
                <span>We’ll send you a 4-digit code to verify your email address.</span>
              )}
            </p>
            <input
              type={state.credentialType === 'phone' ? 'tel' : 'email'}
              required
              autoFocus
              disabled={state.verificationRef?.length > 0}
              value={state.credential}
              onChange={(e) => setCredential(e.target.value)}
              placeholder={
                state.credentialType === 'phone' ? 'Your phone number' : 'Your email address'
              }
              style={{ marginBottom: '1rem' }}
            />
          </>
        )}

        {state.errorMsg.length > 0 && (
          <Alert type="negative">
            <ul>
              {state.errorMsg.split('\n').map((row, ix) => (
                <li key={ix}>{row}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Button variant="block" type="submit" disabled={!state.canSubmit || state.signingIn}>
          {state.verificationRef?.length > 0 ? 'Sign in' : 'Continue'}
        </Button>
      </form>
    </div>
  );
}

export default SigninWithCredential;
