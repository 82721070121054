import gql from 'graphql-tag';

const GQL_REFRESH_AUTHTOKEN = gql`
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshAuthToken(refreshToken: $refreshToken) {
      authToken
    }
  }
`;

const GQL_GET_AUTHTOKEN = gql`
  query GetAuthToken {
    authToken @client {
      token
      validTo
    }
  }
`;

const GQL_SET_AUTHTOKEN = gql`
  mutation SetAuthToken($authToken: String!, $validTo: Int!) {
    setAuthToken(authToken: $authToken, validTo: $validTo) @client
  }
`;

export { GQL_REFRESH_AUTHTOKEN, GQL_GET_AUTHTOKEN, GQL_SET_AUTHTOKEN };
