import {
  formatDistanceToNow,
  format as fnFormat,
  parseISO,
  differenceInDays,
  differenceInWeeks,
  differenceInMinutes,
  isSameDay,
  isBefore,
  isFuture,
  isPast,
  getYear,
  endOfDay,
  startOfDay,
  isValid,
} from 'date-fns';

function ensureDate(str) {
  if (str.constructor !== Date) {
    return parseISO(str);
  }
  return str;
}

function format(str, type = 'relative') {
  if (!str) {
    return null;
  }
  const d = ensureDate(str);
  switch (type) {
    case 'zulu':
      return fnFormat(d, 'yyyy-MM-dd HH:mm');
    case 'date':
      return fnFormat(d, 'yyyy-MM-dd');
    case 'clock':
      return fnFormat(d, 'h:mm a');
    case 'long':
      return fnFormat(d, 'eee, do LLL @ h:mm a');
    case 'weekday':
      return fnFormat(d, 'EEEE');
    case 'month': {
      if (getYear(d) !== new Date().getFullYear()) {
        return fnFormat(d, 'LLL do, yyyy');
      }
      return fnFormat(d, 'LLL do');
    }
    default:
      return formatDistanceToNow(d, { addSuffix: true }).replace('about ', '');
  }
}

function month(str) {
  const d = ensureDate(str);
  return fnFormat(d, 'MMM');
}

function dayOfMonth(str) {
  const d = ensureDate(str);
  return fnFormat(d, 'd');
}

function withinDays(date, days) {
  return differenceInDays(ensureDate(date), new Date()) < days;
}

function sameDay(date1, date2) {
  return isSameDay(ensureDate(date1), ensureDate(date2));
}

function future(date) {
  return isFuture(ensureDate(date));
}

function past(date) {
  return isPast(ensureDate(date));
}

const intervals = {
  w: differenceInWeeks,
};

function occuerencesBetween(start, end, interval = 'w') {
  const d1 = startOfDay(ensureDate(start));
  const d2 = endOfDay(ensureDate(end));
  if (isBefore(d2, d1)) {
    return 0;
  }
  if (!interval) {
    return 0;
  }
  return intervals[interval](d2, d1) + 1;
}

function isValidDate(d) {
  return isValid(d);
}

function diffMinutes(d1, d2) {
  return differenceInMinutes(ensureDate(d1), ensureDate(d2));
}

export {
  format,
  month,
  dayOfMonth,
  withinDays,
  sameDay,
  future,
  past,
  occuerencesBetween,
  isValidDate,
  diffMinutes,
};
