import React, { useEffect, useContext, createContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Bugsnag from '@bugsnag/js';

import { GQL_GET_AUTHTOKEN } from '../infra/authentication/gql';
import { GQL_GET_ME } from '../features/user/gql';
import AuthenticationContext from './Authentication';
import Loading from '../components/Loading';

const UserContext = createContext({
  _id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  profileImageUrl: '',
});

function UserProvider({ children }) {
  const { state, states } = useContext(AuthenticationContext);
  const ctx = useContext(UserContext);
  const { data } = useQuery(GQL_GET_AUTHTOKEN);
  const [getMe, { data: meData }] = useLazyQuery(GQL_GET_ME);
  useEffect(() => {
    async function load() {
      try {
        await getMe();
      } catch (e) {
        Bugsnag.notify(e);
        console.log(e);
      }
    }
    if (state === states.SIGNED_IN && data && data.authToken?.token?.length > 0) {
      load();
    }
  }, [state, states.SIGNED_IN, data, getMe]);
  useEffect(() => {
    const user = meData?.me;
    if (user?.fullName) {
      Bugsnag.setUser(user._id, '', user.fullName);
    }
  }, [meData]);
  return (
    <UserContext.Provider value={{ user: meData?.me ?? ctx }}>
      {!meData ? <Loading /> : children}
    </UserContext.Provider>
  );
}

export { UserProvider };

export default UserContext;
