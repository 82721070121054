import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  defaultDataIdFromObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import Bugsnag from '@bugsnag/js';
import { GQL_SELECTED_TEAM } from './features/team/gql';
import { GQL_GET_AUTHTOKEN } from './infra/authentication/gql';
import { loginByCookie } from './network/auth';
import introspectionQueryResultData from './fragmentTypes.json';

let apollo;
const resetRefreshToken = async () => Promise.resolve();

const inMemoryCache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
  dataIdFromObject: (object) => {
    if (object.cacheKey) {
      return object.cacheKey;
    }
    return defaultDataIdFromObject(object);
  },
});

function getPersistedTeamId() {
  return window.localStorage.getItem('selectedTeam');
}

const resolvers = {
  Query: {
    selectedTeam: () => null,
    authToken: () => ({ token: '', validTo: 0 }),
    errorMessage: () => '',
  },
  Mutation: {
    setAuthToken(_root, { authToken, validTo }, { cache }) {
      return cache.writeData({
        data: { authToken: { token: authToken, validTo, __typename: 'AuthToken' } },
      });
    },
    setSelectedTeam(_root, { teamid }, { cache }) {
      if (teamid) {
        window.localStorage.setItem('selectedTeam', teamid);
      } else {
        window.localStorage.removeItem('selectedTeam');
      }
      return cache.writeData({ data: { selectedTeam: teamid } });
    },
    setErrorMessage(_root, { message }, { cache }) {
      return cache.writeData({ data: { errorMessage: message } });
    },
    async logout(_root, _variables, { cache }) {
      window.localStorage.removeItem('selectedTeam');
      await resetRefreshToken();
      cache.writeData({
        data: { authToken: { token: '', validTo: 0, __typename: 'AuthToken' }, selectedTeam: null },
      });
      window.localStorage.removeItem('selectedTeam');
    },
  },
  Activity: {
    status({ status }) {
      return {
        deleted: status?.deleted ?? false,
        __typename: 'ActivityStatus',
      };
    },
    state({ cancelled, status, isPast }) {
      if (cancelled) {
        return 'cancelled';
      }
      if (status?.deleted) {
        return 'deleted';
      }
      if (isPast) {
        return 'past';
      }
      return 'upcoming';
    },
  },
};

const request = async (operation) => {
  const { authToken: cached } = apollo.cache.readQuery({ query: GQL_GET_AUTHTOKEN });
  let authToken = cached.token;
  if (authToken.length > 0 && new Date().getTime() >= cached.validTo) {
    const { data } = await loginByCookie();
    apollo.writeData({
      data: {
        authToken: {
          token: data.authToken,
          validTo: parseInt(data.authTokenValidTo, 10) - 5000, // Add some margin to when we need to refresh token
          __typename: 'AuthToken',
        },
      },
    });
    authToken = data.authToken;
  }
  const queryHasTeamId = operation.query.definitions.some((def) =>
    def?.variableDefinitions?.some((vb) => vb.variable.name.value === 'teamid'),
  );
  if (queryHasTeamId && !operation.variables.teamid) {
    const { selectedTeam } = apollo.cache.readQuery({ query: GQL_SELECTED_TEAM });
    if (selectedTeam && authToken) {
      operation.variables = {
        ...operation.variables,
        teamid: selectedTeam,
      };
    } else {
      return null;
    }
  }
  operation.setContext({
    headers: {
      authorization: `Bearer ${authToken}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
});

function getApolloClient() {
  if (apollo) {
    return apollo;
  }
  apollo = new ApolloClient({
    cache: inMemoryCache,
    resolvers,
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          const messages = graphQLErrors.map(({ message, locations, path }) => {
            console.log(
              `[GraphQL error]: ${message}, Location: ${JSON.stringify(
                locations,
                '',
                2,
              )}, Path: ${path}`,
            );
            return message;
          });
          const errorMessage = messages.join(' - ');
          apollo.writeData({ data: { errorMessage } });
        }
        if (networkError) {
          console.log(`[Network error]: ${JSON.stringify(networkError, '', 2)}`);
          Bugsnag.notify(new Error(`[Network error]: ${networkError}`));
          apollo.writeData({ data: { errorMessage: networkError.toString() } });
        }
      }),
      requestLink,
      httpLink,
    ]),
  });
  return apollo;
}

(function populateApolloCacheOnBoot() {
  const client = getApolloClient();
  client.writeData({
    data: {
      selectedTeam: getPersistedTeamId(),
      authToken: { token: '', validTo: 0, __typename: 'AuthToken' },
      errorMessage: '',
    },
  });
})();

export { getApolloClient };
