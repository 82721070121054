import * as React from 'react';

function AttendanceIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15 6H3v2h12V6zM15 10H3v2h12v-2zM3 14h8v2H3v-2zM11.99 15.025l1.415-1.414 2.121 2.121 4.243-4.242 1.414 1.414-5.657 5.657-3.535-3.536z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoAttendanceIcon = React.memo(AttendanceIcon);
export default MemoAttendanceIcon;
