import { useQuery } from '@apollo/react-hooks';
import FeedItem from 'components/FeedItem';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import LayoutHeader from '../../../components/LayoutHeader';
import { GET_POST } from '../gql';

function Post() {
  const history = useHistory();
  const params = useParams();
  const { data } = useQuery(GET_POST, {
    variables: { postid: params.postid },
    returnPartialData: true,
  });
  const post = useMemo(() => data?.post, [data]);

  return (
    <>
      <LayoutHeader helmetTitle="Post" left={<AppButton back onClick={() => history.goBack()} />} />
      <FeedItem {...{ post }} />
    </>
  );
}

export default Post;
