import React from 'react';
import styles from './ComposerText.module.scss';
import TextareaAutosize from 'react-textarea-autosize';

function ComposerText({ text, setText, ...props }) {
  return (
    <TextareaAutosize
      className={styles.textarea}
      onChange={(e) => setText(e.target.value)}
      value={text}
      placeholder="Share something with the team…"
      {...props}
    />
  );
}

export default ComposerText;
