import React, { useState, useCallback } from 'react';
import {
  format,
  setMinutes,
  parseISO,
  getYear,
  getMonth,
  getDate,
  setHours,
  setYear,
  setMonth,
  setDate,
  isValid,
  setSeconds,
  setMilliseconds,
} from 'date-fns';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from './DatetimeModal.module.scss';
import ComposerModal from './ComposerModal';

function parseDate(date) {
  const parsed = parseISO(date);
  if (!isValid(parsed)) {
    return;
  }
  return [getYear(parsed), getMonth(parsed), getDate(parsed)];
}

function parseTime(time) {
  const hrs = time.substring(0, 2);
  const mins = time.substring(time.length - 2);
  return [hrs, mins];
}

export default function DatetimeModal({ onClose, date, setFn, title, onlyDate }) {
  const [thedate, setThedate] = useState(format(date, 'yyyy-MM-dd'));
  const [thetime, setThetime] = useState(format(date, 'HH:mm'));
  const [inputError, setInputError] = useState('');
  const onSave = useCallback(() => {
    setInputError('');
    let newDate = setSeconds(setMilliseconds(new Date(), 0), 0);

    try {
      const [y, m, d] = parseDate(thedate);
      newDate = setYear(setMonth(setDate(newDate, d), m), y);
    } catch (e) {
      setInputError('date');
      return;
    }
    if (!onlyDate) {
      try {
        const [hrs, mins] = parseTime(thetime);
        newDate = setHours(setMinutes(newDate, mins), hrs);
      } catch (e) {
        setInputError('time');
        return;
      }
    }
    setFn(newDate);
    onClose();
  }, [thedate, thetime, setFn, onClose, onlyDate, setInputError]);
  return (
    <ComposerModal onClose={onClose} title={title}>
      <div className={styles.datetime}>
        <Input
          value={thedate}
          onChange={setThedate}
          label="Date"
          type="date"
          required
          className={[styles.datetimeDate, inputError === 'date' && styles.datetimeError].join(' ')}
        />
        {!onlyDate && (
          <Input
            value={thetime}
            onChange={setThetime}
            label="Time"
            type="time"
            required
            className={[styles.datetimeTime, inputError === 'time' && styles.timeError].join(' ')}
          />
        )}
      </div>
      <div className={styles.datetimeButtonWrapper}>
        <Button type="button" onClick={onSave}>
          Save
        </Button>
      </div>
    </ComposerModal>
  );
}
