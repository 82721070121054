import React, { useMemo, useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Button from 'components/Button';
import Input from 'components/Input';
import Box from 'components/Box';
import ListItem from 'components/ListItem';
import styles from './NonProTeam.module.scss';
import { GQL_ACTIVATE_TEAM } from './gql';
import PremiumBadge from './PremiumBadge';
import TeamContext from 'contexts/Team';

const validKeyStarts = [
  'PRO-Y-',
  'LPRO-Y-',
  'STND-Y-',
  'PRO-M-',
  'LPRO-M-',
  'STND-M-',
  'PRO-BWKND-',
  'PRO-Z-',
];
function isFunctionalKey(key) {
  return validKeyStarts.some((e) => key.includes(e));
}

function isTeamCode(key) {
  return /^[a-z]{2}-?\d{6}$/i.test(key.trim());
}

function NonProTeam({ team }) {
  const [licenseKey, setLicenseKey] = useState('');
  const { setSelectedTeam } = useContext(TeamContext);
  const [activateTeam, { loading }] = useMutation(GQL_ACTIVATE_TEAM, {
    variables: {
      teamid: team._id,
      key: licenseKey,
    },
    refetchQueries: ['GetMeTeams'],
    onCompleted: (data) => {
      if (data.activateTeam) {
        setSelectedTeam(team._id);
      }
    },
  });
  const canSubmit = useMemo(() => isFunctionalKey(licenseKey) && !loading, [licenseKey, loading]);
  const onSubmit = (e) => {
    e.preventDefault();
    activateTeam();
  };
  return (
    <Box noPadding className={styles.box}>
      <ListItem
        title={team.name}
        right={
          <div className={styles.teamRight}>
            <PremiumBadge packageId={team.activePackage} />
          </div>
        }
        content={
          team.activePackage === 'free' ? (
            <>
              <form onSubmit={onSubmit} className={styles.licenseKey}>
                <Input
                  className={styles.licenseKeyInput}
                  value={licenseKey}
                  onChange={(value) => setLicenseKey(value.trim())}
                  placeholder="License key"
                />
                <div style={{ width: '1rem' }} />
                <Button type="submit" disabled={!canSubmit} loading={loading}>
                  Activate
                </Button>
              </form>
              {isTeamCode(licenseKey) && (
                <p className={styles.licenseKeyError}>
                  That looks like a team code! Your license key can be found in the email you
                  received upon purchase.
                </p>
              )}
            </>
          ) : null
        }
      />
    </Box>
  );
}

export default NonProTeam;
