import React from 'react';
import styles from './ListItem.module.scss';
import classNames from 'classnames';

/**
 * @param {{
 * title?: string,
 * subTitle?: string | JSX.Element,
 * left?: JSX.Element,
 * right?: JSX.Element,
 * content?: JSX.Element,
 * borderBottom?: boolean,
 * thin?: boolean,
 * reverse?: boolean }
 * & JSX.IntrinsicElements['div'] } props
 */
const ListItem = ({
  title,
  subTitle,
  left,
  right,
  borderBottom,
  thin,
  content,
  className,
  reverse,
  ...rest
}) => (
  <div
    className={[
      classNames({
        [styles.border]: borderBottom,
        [styles.thin]: thin,
      }),
      className,
    ].join(' ')}
    {...rest}
  >
    <div className={styles.flex}>
      {!!left && <div className={styles.left}>{left}</div>}
      <div className={styles.middle}>
        {reverse ? (
          <>
            {!!subTitle && <small>{title}</small>}
            {!!title && <div className={styles.title}>{subTitle}</div>}
          </>
        ) : (
          <>
            {!!title && <div className={styles.title}>{title}</div>}
            {!!subTitle && <small>{subTitle}</small>}
          </>
        )}
      </div>
      {!!right && <div className={styles.right}>{right}</div>}
    </div>
    {content}
  </div>
);

/**
 * @param {{ className?: string } & JSX.IntrinsicElements['div'] } props
 */
ListItem.Title = ({ children, className, ...rest }) => (
  <div className={[styles.listItemTitle, className].join(' ')} {...rest}>
    {children}
  </div>
);

export default ListItem;
