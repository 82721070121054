import memoize from 'lodash.memoize';

const {
  REACT_APP_LINK_API_URL: linkPreviewUrl,
  REACT_APP_LINK_API_KEY: linkPreviewApiKey,
} = process.env;

const headers = new Headers({
  'x-api-key': linkPreviewApiKey,
  'Content-Type': 'application/json',
});

const getLinkPreview = memoize(async (url) => {
  const params = new URLSearchParams({ link: url });
  const fetchUrl = `${linkPreviewUrl}/link-preview?${params}`;

  try {
    const response = await fetch(fetchUrl, { headers });
    if (response.status !== 200) {
      return null;
    }

    const { data } = await response.json();
    return {
      image: data?.image?.url || data?.icon?.url,
      title: data?.title,
      siteName: data?.domain,
    };
  } catch {
    return null;
  }
});

const URL_REGEX = /(https?:\/\/)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

function extractLinks(text) {
  return text.match(URL_REGEX) || [];
}

export { getLinkPreview, extractLinks };
