import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_COMMENT } from '../gql';
import Button from 'components/Button';
import styles from './Post.module.scss';

function PostCommentForm({ postid, creator }) {
  const [comment, setComment] = useState('');
  const [mutationPostComment, { loading: postCommentLoading }] = useMutation(CREATE_COMMENT);
  const doSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await mutationPostComment({
        variables: {
          postid,
          commentText: comment,
        },
      });
      setComment('');
    },
    [comment, mutationPostComment, postid],
  );
  return (
    <form onSubmit={doSubmit} className={styles.commentForm}>
      <input
        type="text"
        value={comment}
        onChange={(e) => setComment(e.currentTarget.value)}
        disabled={postCommentLoading}
        className={styles.commentFormInput}
        placeholder={`Reply on ${creator?.firstName}'s post`}
      />
      <Button
        type="submit"
        loading={postCommentLoading}
        className={styles.commentFormButton}
        disabled={comment === ''}
      >
        Send
      </Button>
    </form>
  );
}

export default PostCommentForm;
