import React from 'react';

function StandardPill() {
  return (
    <div
      style={{
        backgroundColor: '#d8a250',
        color: '#fff',
        fontSize: '.5rem',
        fontWeight: 500,
        padding: '.1rem .3rem',
        borderRadius: '1rem',
        letterSpacing: '.05rem',
      }}
    >
      Standard
    </div>
  );
}

export default StandardPill;
