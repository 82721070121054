import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { humanFileSize } from 'features/posts/lib';
import { GET_FILE_DOWNLOAD_URL } from 'features/posts/gql';
import IconSpinner from 'components/IconSpinner';
import styles from './DocumentAttachment.module.scss';

function DocumentAttachment({ attachment }) {
  const { url, typeIcon, title, size, contentType } = attachment;
  const [isWorking, setIsWorking] = useState(false);
  const downloadUrlCompleted = async (data) => {
    if (data?.signedDownloadUrl) {
      const request = await axios.get(data.signedDownloadUrl, { responseType: 'blob' });
      setIsWorking(false);
      fileDownload(request.data, title, contentType);
    }
  };
  const [getDownloadUrl] = useLazyQuery(GET_FILE_DOWNLOAD_URL, {
    onCompleted: downloadUrlCompleted,
    onError: () => {
      setIsWorking(false);
    },
    fetchPolicy: 'network-only',
  });
  const downloadLink = (filename) => (e) => {
    e.preventDefault();
    setIsWorking(true);
    getDownloadUrl({ variables: { filename } });
  };
  return (
    <div className={styles.wrapper}>
      <img src={typeIcon} alt="Document icon" />
      <a href={url} onClick={downloadLink(url)}>
        {title}
      </a>
      <div style={{ width: '0.25rem' }} />
      <small>({humanFileSize(size)})</small>
      {isWorking && <IconSpinner className={styles.spin} />}
    </div>
  );
}

export default DocumentAttachment;
