import { ulid } from 'ulid';

function getFileAsDataURL(file) {
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onload = () => res(reader.result);
    reader.readAsDataURL(file);
  });
}

function envelopeImage({ attachments }) {
  return async (file) => {
    if (!file.type.includes('image/')) {
      return { message: 'File is not an image' };
    }
    if (attachments.some((a) => a.file.name === file.name)) {
      return { message: 'Please choose another image' };
    }
    const result = await getFileAsDataURL(file);
    return {
      id: ulid().toLowerCase(),
      file,
      data: result,
      uploading: true,
    };
  };
}

async function handleImages({ images, attachments }) {
  return (await Promise.all(images.map(envelopeImage({ attachments })))).filter((a) => a.file);
}

async function envelopeFile({ file }) {
  const fileDataUrl = await getFileAsDataURL(file);
  return {
    id: ulid().toLowerCase(),
    file,
    fileData: fileDataUrl,
    loading: true,
  };
}

export { envelopeImage, handleImages, envelopeFile };
