import React from 'react';
import styles from './Activity.module.scss';

function ActivityTag({ tag }) {
  if (!tag) {
    return null;
  }
  return (
    <div className={styles.tag} style={{ color: tag.color }}>
      {tag.name}
    </div>
  );
}

export default ActivityTag;
