import Button from 'components/Button';
import { differenceInMinutes, subMinutes } from 'date-fns';
import React, { useCallback, useState } from 'react';
import ComposerModal from './ComposerModal';
import MeetDateInput from './MeetDateInput';
import styles from './MeetDateModal.module.scss';

export default function MeetDateModal({ meetdate, startdate, setFn, onClose, title }) {
  const [selectedMeetDate, setSelectedMeetDate] = useState(
    meetdate ? differenceInMinutes(startdate, meetdate) : '',
  );

  const onSave = useCallback(() => {
    const newMeetDate = subMinutes(startdate, selectedMeetDate);
    setFn(differenceInMinutes(startdate, newMeetDate));
    onClose();
  }, [startdate, setFn, onClose, selectedMeetDate]);

  return (
    <ComposerModal title={title} onClose={onClose}>
      <MeetDateInput onChange={setSelectedMeetDate} value={selectedMeetDate}></MeetDateInput>
      <div className={styles.meetdateButtonWrapper}>
        <Button type="button" onClick={onSave}>
          Save
        </Button>
      </div>
    </ComposerModal>
  );
}
