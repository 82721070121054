import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import TeamContext from '../../contexts/Team';
import Image from '../Image';
import styles from './ProfileImage.module.scss';

const initials = _.memoize((name) => {
  const [first, second] = name.split(' ');
  return `${first?.slice(0, 1) ?? ''}${second?.slice(0, 1) ?? ''}`;
});

const ProfileImage = ({ user, size = 36 }) => {
  const { team } = useContext(TeamContext);
  const [backgroundColor, setBackgroundColor] = useState('#333');
  const [renderFallback, setRenderfallback] = useState(false);

  useEffect(() => {
    const color = team?.color;
    if (color) {
      setBackgroundColor(color);
    }
  }, [team]);

  if (!user) {
    return null;
  }

  return (
    <div className={styles.wrapper} style={{ width: size, height: size, backgroundColor }}>
      {user.profileImage && !renderFallback ? (
        <Image
          url={user.profileImage}
          width={128}
          style={{ height: size }}
          onError={() => setRenderfallback(true)}
        />
      ) : (
        <small style={{ fontSize: size / 3 + 'px', fontWeight: 500 }}>
          {initials(user.fullName)}
        </small>
      )}
    </div>
  );
};

export default ProfileImage;
