import * as React from 'react';

function CaretIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.343 7.757L4.93 9.172 12 16.242l7.071-7.07-1.414-1.415L12 13.414 6.343 7.757z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoCaretIcon = React.memo(CaretIcon);
export default MemoCaretIcon;
