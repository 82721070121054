import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { GET_PROSUBSCRIPTIONS_BY_ORDERID } from './gql';
import ListSubscriptions from './ListSubscriptions';

function ActivateFastspringSubs() {
  const { orderid } = useParams();
  const { data, loading } = useQuery(GET_PROSUBSCRIPTIONS_BY_ORDERID, {
    variables: {
      orderid,
    },
  });
  const [used, unused] = useMemo(
    () => _.partition(data?.getSubscriptionsWithOrderID, (sub) => sub.teams.length),
    [data],
  );
  return <ListSubscriptions loading={loading} used={used} unused={unused} />;
}

export default ActivateFastspringSubs;
