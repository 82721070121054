import React from 'react';
import IconCheckbox from './IconCheckbox';
import styles from './CheckboxButton.module.scss';

function IconCheckboxChecked() {
  return (
    <IconCheckbox className={styles.checkboxIconChecked}>
      <div className={styles.checkIcon}>
        <svg width={26} height={26} fill="none">
          <path d="M12 11L8 7 6 9l6 6L22 5l-2-2-8 8z" fill="#fff" />
        </svg>
      </div>
    </IconCheckbox>
  );
}

const MemoIconCheckboxChecked = React.memo(IconCheckboxChecked);
export default MemoIconCheckboxChecked;
