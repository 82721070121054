import React, { useEffect, useRef } from 'react';
import styles from './Input.module.scss';

function InputLabel({ children, className }) {
  return <label className={[styles.label, className].join(' ')}>{children}</label>;
}

function Input({
  label,
  type,
  value,
  onChange,
  required,
  disabled,
  autoFocus,
  className,
  ...rest
}) {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <div className={[className, disabled && styles.disabled].join(' ')}>
      {!!label && <InputLabel>{label}</InputLabel>}
      <input
        ref={inputRef}
        type={type || 'text'}
        value={value}
        onChange={(e) => onChange && !disabled && onChange(e.target.value)}
        required={required}
        disabled={disabled}
        className={styles.input}
        {...rest}
      />
    </div>
  );
}

Input.Label = InputLabel;

export default Input;
