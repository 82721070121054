import gql from 'graphql-tag';

const fragmentTeamMember = gql`
  fragment CoachMember on CoachMember {
    _id
    userid
    fullName
    profileImage
    address
    phone
    email
    tags
    children {
      _id
      fullName
    }
  }

  fragment PlayerMember on PlayerMember {
    _id
    userid
    fullName
    profileImage
    address
    phone
    email
    tags
    parents {
      _id
      fullName
    }
  }

  fragment ParentMember on ParentMember {
    _id
    userid
    fullName
    profileImage
    address
    phone
    email
    tags
    children {
      _id
      fullName
    }
  }
`;

const fragments = {
  member: fragmentTeamMember,
};

const GQL_SELECTED_TEAM = gql`
  query GetSelectedTeam {
    selectedTeam @client
  }
`;

const GQL_SET_SELECTED_TEAM = gql`
  mutation SetSelectedTeam($teamid: String!) {
    setSelectedTeam(teamid: $teamid) @client
  }
`;

const GQL_GET_USER_TEAMS = gql`
  query GetMeTeams {
    me {
      _id
      teams {
        _id
        name
        numMembers
        isPro
      }
    }
  }
`;

const GQL_GET_TEAM = gql`
  query GetTeam($teamid: String!) {
    team(input: { teamid: $teamid }) {
      _id
      name
      admins
      color
      code
      activityTags {
        name
        color
      }
      me {
        isAdmin
        isCoach
      }
      coaches {
        ...CoachMember
      }
      players {
        ...PlayerMember
      }
      parents {
        ...ParentMember
      }
      image
    }
  }
  ${fragmentTeamMember}
`;

const GQL_TEAM_MEMBER = gql`
  query GetTeamMember($teamid: String!, $memberid: String, $userid: String) {
    teamMember(input: { teamid: $teamid, memberid: $memberid, userid: $userid }) {
      ... on CoachMember {
        ...CoachMember
      }
      ... on PlayerMember {
        ...PlayerMember
      }
      ... on ParentMember {
        ...ParentMember
      }
    }
  }
  ${fragmentTeamMember}
`;

export {
  GQL_SELECTED_TEAM,
  GQL_SET_SELECTED_TEAM,
  GQL_GET_USER_TEAMS,
  GQL_GET_TEAM,
  GQL_TEAM_MEMBER,
  fragments,
};
