import * as React from 'react';

function BackIcon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.328 11v2H7.5l3.243 3.243-1.415 1.414L3.672 12l5.656-5.657 1.415 1.414L7.5 11h12.828z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoBackIcon = React.memo(BackIcon);
export default MemoBackIcon;
