// Stolen here: https://stackoverflow.com/a/20732091/109590
function humanFileSize(size) {
  if (size <= 0) {
    return '-';
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(0) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export { humanFileSize };
