import React from 'react';
import ToggleMenu from 'components/ToggleMenu';
import styles from './AttendanceScreen.module.scss';
import { types, ranges } from './utils';

function AttendanceFilters({
  selectedType,
  setSelectedType,
  selectedRange,
  setSelectedRange,
  ignoredType,
}) {
  return (
    <div className={styles.filters}>
      <ToggleMenu
        toggleText={types.find((type) => type.value === selectedType).label}
        menuItems={types
          .filter((tag) => tag.value !== ignoredType)
          .map((type) => ({
            content: (
              <button
                onClick={() => {
                  setSelectedType(type.value);
                }}
              >
                {type.label}
              </button>
            ),
          }))}
      />
      <ToggleMenu
        toggleText={ranges.find((range) => range.value === selectedRange).label}
        menuItems={ranges.map((range) => ({
          content: (
            <button
              onClick={() => {
                setSelectedRange(range.value);
              }}
            >
              {range.label}
            </button>
          ),
        }))}
      />
    </div>
  );
}

export default AttendanceFilters;
