import React from 'react';
import imageUrl from '../services/images';

const Image = ({ url, width, ...rest }) => {
  // If there's alt text, or classNames they'll spread out with ...rest
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={imageUrl({ url, width })} {...rest} />;
};

export default Image;
