import React, { useCallback } from 'react';
import { useKeyPress } from '../../hooks/useKeyPress';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import Image from '../Image';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import styles from './Gallery.module.scss';

export const Lightbox = ({ images, setLightboxIsOpen, imageIndex, setImageIndex }) => {
  useLockBodyScroll();

  const goToPrev = useCallback(
    () => setImageIndex((imageIndex + images.length - 1) % images.length),
    [imageIndex, images.length, setImageIndex],
  );

  const goToNext = useCallback(() => setImageIndex((imageIndex + 1) % images.length), [
    imageIndex,
    images.length,
    setImageIndex,
  ]);

  useKeyPress('ArrowRight', goToNext);
  useKeyPress('ArrowLeft', goToPrev);
  useKeyPress('Escape', () => setLightboxIsOpen(false));

  return (
    <div className={styles.overlay}>
      {images.length > 1 && (
        <ChevronLeft
          className={[styles.chevron, styles.chevronPrev].join(' ')}
          onClick={goToPrev}
        />
      )}

      <div className={styles.tapToClose} onClick={() => setLightboxIsOpen(false)} />

      <div className={styles.overlayImage}>
        <Image url={images[imageIndex].url} width={2048} />
      </div>

      {images.length > 1 && (
        <ChevronRight
          className={[styles.chevron, styles.chevronNext].join(' ')}
          onClick={goToNext}
        />
      )}
    </div>
  );
};
