import React, { useEffect, useMemo, useState } from 'react';
import Input from 'components/Input';
import CheckboxButton from '../CheckboxButton';
import styles from './RSVPDueDate.module.scss';
import { format } from 'date-fns';

export default function RSVPDueDate({ rsvpDueDate, setRsvpDueDate, startDate }) {
  const [showRsvpDueDate, setShowRsvpDueDate] = useState(!!rsvpDueDate);
  const minDate = useMemo(() => format(new Date(), `yyyy-MM-dd'T'HH:mm`), []);

  const value = useMemo(() => {
    if (!rsvpDueDate) {
      return minDate;
    }

    const parsedDate = typeof rsvpDueDate === 'string' ? new Date(rsvpDueDate) : rsvpDueDate;
    const formattedDate = format(parsedDate, `yyyy-MM-dd'T'HH:mm`);

    return formattedDate;
  }, [minDate, rsvpDueDate]);

  useEffect(() => {
    if (!showRsvpDueDate && rsvpDueDate) {
      setShowRsvpDueDate(true);
    }
  }, [rsvpDueDate, showRsvpDueDate]);

  return (
    <>
      <Input.Label>RSVP by</Input.Label>
      <div className={styles.rsvpDueDateToggle}>
        <CheckboxButton
          checked={!showRsvpDueDate}
          onClick={() => {
            setShowRsvpDueDate(false);
            setRsvpDueDate(null);
          }}
        >
          Disable RSVP time
        </CheckboxButton>

        <div className={styles.space} />

        <CheckboxButton
          checked={showRsvpDueDate}
          onClick={() => {
            setShowRsvpDueDate(true);
            setRsvpDueDate(minDate);
          }}
        >
          Enable RSVP time
        </CheckboxButton>
      </div>

      {showRsvpDueDate && (
        <Input
          value={value}
          placeholder="RSVP by date"
          onChange={setRsvpDueDate}
          type="datetime-local"
          max={format(startDate, `yyyy-MM-dd'T'HH:mm`)}
          min={minDate}
        />
      )}
    </>
  );
}
