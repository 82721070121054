import { useMutation } from '@apollo/react-hooks';
import DocumentAttachment from 'features/posts/Post/DocumentAttachment';
import LinkPreview from 'features/posts/Post/LinkPreview';
import PostCommentForm from 'features/posts/Post/PostCommentForm';
import PostFooter from 'features/posts/Post/PostFooter';
import { format } from 'services/date';
import React, { useMemo } from 'react';
import ReactHLS from 'react-hls-player';
import { Link, useLocation } from 'react-router-dom';
import Box from '../Box';
import Gallery from '../Gallery';
import ListItem from '../ListItem';
import ProfileImage from '../ProfileImage';
import Comments from './Comments';
import VisibilitySensor from 'react-visibility-sensor';
import { SET_SEENBY } from 'features/posts/gql';
import useUser from 'hooks/useUser';
import { extractLinks } from 'services/links';
import PostContent from 'features/posts/Post/PostContent';

const FeedItem = ({ post }) => {
  const location = useLocation();
  const isFeed = location.pathname === '/posts';

  const [mutateSeenBy] = useMutation(SET_SEENBY);
  const user = useUser();
  const meHaveSeen = useMemo(() => post?.seenBy?.find((sb) => sb.user._id === user._id)?.seen, [
    post,
    user._id,
  ]);
  const shouldSetSeenBy = (isVisible) => {
    if (isFeed && isVisible && !meHaveSeen) {
      mutateSeenBy({
        variables: { postid: post._id },
      });
    }
  };

  const {
    _id,
    creator,
    publishdate,
    text,
    images = [],
    videos = [],
    documents = [],
    seenBy = [],
    reactions = [],
    comments = [],
  } = post;

  const Header = (
    <ListItem
      left={<ProfileImage user={creator} />}
      title={creator?.fullName}
      subTitle={format(publishdate)}
      style={{ marginBottom: '1rem' }}
    />
  );

  const links = extractLinks(text);

  return (
    <VisibilitySensor partialVisibility onChange={shouldSetSeenBy}>
      <Box>
        {isFeed ? (
          <Link to={`/posts/${_id}`}>
            {Header}
            <PostContent text={text} />
          </Link>
        ) : (
          <>
            {Header}
            <PostContent text={text} />
          </>
        )}

        {images?.length > 0 && <Gallery {...{ images }} />}

        {videos?.length > 0 && (
          <ReactHLS
            url={videos[0].url}
            controls
            width={500}
            height={400}
            hlsConfig={{ enableWorker: false }}
          />
        )}

        {documents?.length > 0 &&
          documents.map((d) => <DocumentAttachment key={d.url} attachment={d} />)}

        {links.map((link) => (
          <LinkPreview key={link} url={link} />
        ))}
        <PostFooter {...{ postid: _id, seenBy, reactions }} />

        <ListItem.Title>Comments</ListItem.Title>
        {comments?.length > 0 ? (
          <Comments postid={_id} comments={comments} />
        ) : (
          <ListItem
            title="No comments yet"
            subTitle="Be the first to say something"
            style={{ marginBottom: '1rem' }}
          />
        )}
        <PostCommentForm postid={_id} creator={creator} />
      </Box>
    </VisibilitySensor>
  );
};

const LoadableFeedItem = ({ post }) => {
  if (!post) return null;
  return <FeedItem {...{ post }} />;
};

export default LoadableFeedItem;
