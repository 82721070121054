import React from 'react';
import { Helmet } from 'react-helmet';
import TeamMemberList from './TeamMemberList';

const Team = () => {
  return (
    <>
      <Helmet>
        <title>Team | Heja | Sports team management and communication app</title>
      </Helmet>
      <TeamMemberList small />
    </>
  );
};

export default Team;
