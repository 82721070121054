import React, { useMemo, useEffect, useContext, createContext } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import UserContext from './User';
import { GQL_SELECTED_TEAM, GQL_SET_SELECTED_TEAM, GQL_GET_TEAM } from '../features/team/gql';

const TeamContext = createContext({
  selectedTeam: '',
  setSelectedTeam: (teamid) => {},
  team: {},
});

const ignoredPaths = ['/activate'];

function TeamProvider({ children }) {
  const { user } = useContext(UserContext);
  const { data } = useQuery(GQL_SELECTED_TEAM);
  const [setStoreSelectedTeam] = useMutation(GQL_SET_SELECTED_TEAM);
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const [fetchTeam, { data: teamData }] = useLazyQuery(GQL_GET_TEAM, {
    onError: () => {
      setStoreSelectedTeam({ variables: { teamid: '' } });
      replace('/choose-team');
    },
  });
  const selectedTeam = useMemo(() => data && data.selectedTeam, [data]);

  useEffect(() => {
    if (user?._id?.length > 0 && !selectedTeam && !ignoredPaths.some((p) => pathname.includes(p))) {
      replace('/choose-team');
    }
  }, [user, selectedTeam, pathname, replace]);

  useEffect(() => {
    if (selectedTeam?.length > 0 && selectedTeam !== teamData?._id) {
      fetchTeam({ variables: { teamid: selectedTeam } });
    }
  }, [selectedTeam, fetchTeam, teamData]);

  const setSelectedTeam = (teamid) => {
    setStoreSelectedTeam({ variables: { teamid } });
  };

  // if (!selectedTeam && !user.hasTeams) {
  //   return <NoEligibleTeam />;
  // }

  return (
    <TeamContext.Provider
      value={{
        selectedTeam,
        setSelectedTeam,
        team: teamData?.team || {},
      }}
    >
      {selectedTeam && !teamData ? <Loading /> : children}
    </TeamContext.Provider>
  );
}

export { TeamProvider };

export default TeamContext;
