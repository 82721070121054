import React from 'react';
import Input from 'components/Input';

export default function RSVPSlots({ maxAttendees, setMaxAttendees }) {
  return (
    <Input
      value={maxAttendees || ''}
      placeholder="Open slots"
      onChange={setMaxAttendees}
      label="Available RSVPs"
      type="number"
    />
  );
}
